<template>
  <v-dialog
    :value="showContactDialog"
    :max-width="$vuetify.breakpoint.xsOnly ? '95%' : '500'"
    @input="$emit('input')"
  >
    <v-card class="contact-dialog" dark>
      <v-card-title class="text-center d-flex justify-center">
        <span class="text-h5">Contact Us</span>
      </v-card-title>
      
      <v-divider></v-divider>
      
      <v-card-text class="pa-4">
        <div class="contact-options">
          <a href="mailto:admin@psychurch.org" class="contact-option" target="_blank">
            <div class="icon-wrapper">
              <v-icon>mail</v-icon>
            </div>
            <span>Email</span>
          </a>
          <a v-if="facebookUrl" :href="facebookUrl" class="contact-option" target="_blank">
            <div class="icon-wrapper facebook">
              <v-icon>facebook</v-icon>
            </div>
            <span>Facebook</span>
          </a>
          <a href="https://discord.gg/Mw6QdJVD" class="contact-option" target="_blank">
            <div class="icon-wrapper discord">
              <v-icon>discord</v-icon>
            </div>
            <span>Discord</span>
          </a>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      
      <v-card-actions class="justify-center pa-4">
        <v-btn
          text
          @click="showContactDialog = false"
          class="px-8"
          rounded
        >
          <v-icon left>close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "contactDialog",
  props: ['value'],
  computed: {
    ...mapGetters(['FACEBOOK']),
    showContactDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    facebookUrl() {
      if (!this.FACEBOOK) return null;
      try {
        let url = this.FACEBOOK.trim();
        
        // Ensure the URL starts with https://www.
        if (!url.startsWith('http')) {
          url = `https://${url}`;
        }
        if (!url.includes('www.')) {
          url = url.replace('https://', 'https://www.');
        }
        
        // Validate the URL
        new URL(url);
        console.log('Formatted Facebook URL:', url); // Log the URL for verification
        return url;
      } catch (e) {
        console.error('Invalid Facebook URL:', e);
        return null;
      }
    }
  }
}
</script>

<style lang="scss">
.contact-dialog {
  background: #282C47 !important;

  .contact-options {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .contact-option {
      display: flex;
      align-items: center;
      padding: 16px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
      color: #fff;
      text-decoration: none;
      transition: all 0.2s ease;
      border: 1px solid transparent;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        margin-right: 16px;
        transition: all 0.2s ease;

        &.facebook {
          background: #1877F2;
        }

        &.discord {
          background: #5865F2;
        }
      }

      span:not(.v-icon) {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.3px;
      }

      &:hover {
        transform: translateY(-1px);
        background: rgba(255, 255, 255, 0.08);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        border-color: rgba(255, 255, 255, 0.1);

        .icon-wrapper {
          transform: scale(1.05);
          
          .v-icon {
            transform: scale(1.1);
          }
        }
      }

      &:active {
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 767px) {
    .contact-options {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      .contact-option {
        flex: 0 1 calc(33.333% - 7px);
        flex-direction: column;
        text-align: center;
        padding: 16px 8px;
        min-width: 90px;

        .icon-wrapper {
          margin: 0 0 10px 0;
          width: 46px;
          height: 46px;

          .v-icon {
            font-size: 22px;
          }
        }

        span:not(.v-icon) {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 359px) {
    .contact-options {
      .contact-option {
        flex: 0 1 calc(50% - 5px);
        padding: 16px 12px;

        .icon-wrapper {
          width: 48px;
          height: 48px;
        }

        span:not(.v-icon) {
          font-size: 14px;
        }
      }
    }
  }
}
</style> 