<template>
    <v-dialog
        :value="value" @input="$emit('input'); cancelDialog()"
        max-width="570">

        <div class="dialog-wrapper need-login-dialog">
            <div class="dialog-header">
                <h2>Oops!</h2>
            </div>
            <div class="dialog-content">
                <p>You have to be logged in to do that!</p>
            </div>
            <div class="dialog-actions">
                <button type="button" class="tetriatary_btn medium" @click="cancelDialog" v-ripple>Cancel</button>
                <button type="button" class="c2a_btn medium" @click="goToLogin" v-ripple>Log-in</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "needLogin",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        action: {
            type: String,
            default: null
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
	created() {
		this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', null);
	},
	methods: {
		cancelDialog() {
			this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', false);
			this.dialog = false;
			localStorage.removeItem('add_album_to_favorites');
			localStorage.removeItem('add_track_to_favorites');
		},
        goToLogin() {
	        this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', this.$route.path);
	        this.$router.push({name: 'login'});
	        this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', true);
	        this.dialog = false;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.need-login-dialog {
    @include dialog((
        has-header: true,
        has-content: true,
        has-actions: true,
        content-bordered: true
    ));

    .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        p {
            margin: 0;
            font-size: 16px;
        }
    }
}
</style>