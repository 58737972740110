<template>
    <div class="pagination">
        <div class="left_line"></div>
        <div class="center_box">
            <button type="button" class="previous_page" @click="previousPage" :disabled="+currentPage === 1">Previous</button>

            <div class="select_page">
                <div class="pagination_status" id="pagination-status" @click="toggleListPagination">Page {{currentPage}} of {{totalPages}}</div>
                <v-list class="pagination_list" v-if="viewListPagination" id="pagination-list" :class="{'show': viewListPagination}" v-click-outside="clickOutsidePagination">
                    <v-list-item v-for="item in totalPages" :key="item" @click="selectPage(item)" :id="`list-item-${item}`">
                        <v-list-item-title :class="{'active': item === currentPage}">Page {{ item }} of {{totalPages}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>

            <button type="button" class="next_page" @click="nextPage" :disabled="+currentPage >= totalPages">Next</button>
        </div>
        <div class="right_line"></div>
    </div>
</template>

<script>
import eventBus from "@/eventBus";

export default {
    name: 'musicReleasePagination',
    props: {
        count: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        commit: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            viewListPagination: false
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.count / 30);
        }
    },
    methods: {
        emitPageChange(newPage) {
            eventBus.emit('paginationClick');  // Emit the event before any page change
            this.$emit('pageChanged', newPage);
            this.$emit('scrollTop');
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.emitPageChange(this.currentPage - 1);  // Emit first
                this.$store.commit(this.commit, this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.emitPageChange(this.currentPage + 1);  // Emit first
                this.$store.commit(this.commit, this.currentPage + 1);
            }
        },
        selectPage(item) {
            if (this.currentPage !== item) {
                this.emitPageChange(item);  // Emit first
                this.$store.commit(this.commit, item);
            }
            this.viewListPagination = false;
        },
        toggleListPagination() {
            this.viewListPagination = !this.viewListPagination;
            this.$nextTick(() => {
                let paginationList = document.getElementById(`list-item-${this.currentPage}`);
                if (paginationList) {
                    paginationList.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            });
        },
        clickOutsidePagination(event) {
            if (!event.target.closest('#pagination-status') && !event.target.closest('#pagination-list')) {
                this.viewListPagination = false;
            }
        },
        changePage(page) {
            eventBus.emit('paginationClick');  // Emit event when user clicks pagination
            this.$store.commit(this.commit, page);
        }
    },
};
</script>

<style scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.left_line, .right_line {
    flex-grow: 1;
    height: 1px;
    background-color: rgba(95, 105, 134, 0.5);
}

.center_box {
    display: flex;
    align-items: center;
    margin: 0 20px;
}

button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    cursor: pointer;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.select_page {
    position: relative;
}

.pagination_status {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid rgba(95, 105, 134, 0.5);
    border-radius: 3px;
}

.pagination_list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid rgba(95, 105, 134, 0.5);
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.pagination_list.show {
    display: block;
}

.v-list-item-title.active {
    font-weight: bold;
    background-color: #f0f0f0;
}
</style>