import { Device } from '@capacitor/device';

let deviceInfo = null;

// Map of iPhone model identifiers to their types
const MODEL_TYPES = {
    // Dynamic Island devices
    'iPhone15,2': 'dynamic-island', // iPhone 14 Pro
    'iPhone15,3': 'dynamic-island', // iPhone 14 Pro Max
    'iPhone16,1': 'dynamic-island', // iPhone 15 Pro
    'iPhone16,2': 'dynamic-island', // iPhone 15 Pro Max
    'iPhone17,1': 'dynamic-island', // iPhone 17
    
    // Notch devices (iPhone X through iPhone 15, except Pro models from 14 onwards)
    'iPhone16,3': 'notch', // iPhone 15
    'iPhone16,4': 'notch', // iPhone 15 Plus
    'iPhone14,7': 'notch', // iPhone 14
    'iPhone14,8': 'notch', // iPhone 14 Plus
    'iPhone14,2': 'notch', // iPhone 13 Pro
    'iPhone14,3': 'notch', // iPhone 13 Pro Max
    'iPhone14,4': 'notch', // iPhone 13 mini
    'iPhone14,5': 'notch', // iPhone 13
    'iPhone13,1': 'notch', // iPhone 12 mini
    'iPhone13,2': 'notch', // iPhone 12
    'iPhone13,3': 'notch', // iPhone 12 Pro
    'iPhone13,4': 'notch', // iPhone 12 Pro Max
    'iPhone12,1': 'notch', // iPhone 11
    'iPhone12,3': 'notch', // iPhone 11 Pro
    'iPhone12,5': 'notch', // iPhone 11 Pro Max
    'iPhone11,2': 'notch', // iPhone XS
    'iPhone11,4': 'notch', // iPhone XS Max
    'iPhone11,6': 'notch', // iPhone XS Max Global
    'iPhone11,8': 'notch', // iPhone XR
    'iPhone10,3': 'notch', // iPhone X
    'iPhone10,6': 'notch', // iPhone X GSM
    
    // Standard devices (no notch, no dynamic island)
    'iPhone14,6': 'standard', // iPhone SE (3rd gen, 2022)
    'iPhone12,8': 'standard', // iPhone SE (2nd gen, 2020)
    'iPhone8,4': 'standard',  // iPhone SE (1st gen)
    'iPhone10,1': 'standard', // iPhone 8
    'iPhone10,4': 'standard', // iPhone 8
    'iPhone10,2': 'standard', // iPhone 8 Plus
    'iPhone10,5': 'standard', // iPhone 8 Plus
    'iPhone9,1': 'standard',  // iPhone 7
    'iPhone9,3': 'standard',  // iPhone 7
    'iPhone9,2': 'standard',  // iPhone 7 Plus
    'iPhone9,4': 'standard',  // iPhone 7 Plus
    'iPhone8,1': 'standard',  // iPhone 6s
    'iPhone8,2': 'standard',  // iPhone 6s Plus
};

export const initDeviceInfo = async () => {
    try {
        deviceInfo = await Device.getInfo();
        const { model, platform, operatingSystem } = deviceInfo;
        
        // Add device-specific classes to both HTML and body elements
        const html = document.documentElement;
        const body = document.body;
        
        // Add app-mode class
        body.classList.add('app-mode');
        
        // Add device platform class
        body.classList.add(`device-${platform.toLowerCase()}`);
        
        // Store the device model for display
        body.style.setProperty('--device-model', `'${model}'`);
        
        // iOS-specific handling
        if (platform === 'ios') {
            console.log('----------------------------------------');
            console.log('Device Detection Debug Info:');
            console.log('Device Model:', model);
            console.log('Platform:', platform);
            console.log('Body Classes Before:', body.className);
            
            const deviceType = MODEL_TYPES[model] || 'notch';
            console.log('Looking up model in mapping:', MODEL_TYPES[model]);
            console.log('Final Detected Type:', deviceType);
            
            // Remove all possible classes first from body
            body.classList.remove('has-dynamic-island', 'has-notch', 'no-notch');
            
            // Add the appropriate class and set safe area
            switch(deviceType) {
                case 'dynamic-island':
                    body.classList.add('has-dynamic-island');
                    body.style.setProperty('--safe-area-top', '59px');
                    console.log('Adding Dynamic Island class');
                    break;
                case 'notch':
                    body.classList.add('has-notch');
                    body.style.setProperty('--safe-area-top', '47px');
                    console.log('Adding Notch class');
                    break;
                case 'standard':
                    body.classList.add('no-notch');
                    body.style.setProperty('--safe-area-top', '20px');
                    console.log('Adding Standard class');
                    break;
            }
            
            // Debug: Log final state
            console.log('Body Classes After:', body.className);
            console.log('Safe Area Top:', body.style.getPropertyValue('--safe-area-top'));
            console.log('----------------------------------------');
        }
        
        return deviceInfo;
    } catch (error) {
        console.error('Error getting device info:', error);
        // Fallback to safe defaults
        document.body.style.setProperty('--safe-area-top', '54px');
        return null;
    }
};

export const getDeviceInfo = () => deviceInfo; 