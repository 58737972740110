<template>
  <v-dialog
    :value="value" 
    @input="$emit('input')"
    max-width="570"
  >
    <div class="dialog-wrapper artist-verification-dialog">
      <div class="dialog-header">
        <h2>Are you {{ artist.name }}?</h2>
      </div>
      <div class="dialog-content">
        <p>You can claim this profile as your own</p>
      </div>
      <div class="dialog-actions">
        <button 
          type="button" 
          class="tetriatary_btn medium" 
          @click="dialog = false" 
          v-ripple
        >
          Cancel
        </button>
        <router-link 
          :to="{name: 'upload-verification', params: {artistId: artist.id}}" 
          class="primary_btn medium" 
          v-ripple
        >
          Verify Me
        </router-link>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "areYouThisArtist",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    artist: {
      type: Object,
      required: true
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.artist-verification-dialog {
  @include dialog((
    has-header: true,
    has-content: true,
    has-actions: true,
    content-bordered: true
  ));

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p {
      margin: 0;
      font-size: 16px;
    }
  }
}
</style>
