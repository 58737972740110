import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

import store from '../store';

// routes
import auth from '@/routes/auth';
import adminPages from '@/routes/admin-pages';
import privatePages from '@/routes/private-pages';
import publicPages from '@/routes/public-pages';

import profiles from '@/routes/profiles';
import events from '@/routes/events';
import settings from '@/routes/settings';
import support from '@/routes/support';
import dashboard from '@/routes/dashboard';

import manage from '@/routes/manage';
import other from '@/routes/other';
import {keepRouteParams, takeRouteParams} from "@/utils/route.utils";

// Import the explore component
import explore from '@/pages/explore.vue';

const routes = [
    ...auth,
    ...adminPages,
    ...privatePages,
    ...publicPages,
    ...events,
    ...settings,
    ...support,
    ...dashboard,
    ...profiles,
    ...manage,
    ...other,
    {
        path: '/explore/:subgenre?',
        name: 'explore',
        component: explore,
        props: true
    },
    {
        path: '/favorites',
        name: 'favorites',
        component: explore
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('@/pages/search/search.vue'),
        beforeRouteLeave(to, from, next) {
            // Reset search state when leaving search-related pages
            if (!['search', 'searchDetails'].includes(to.name)) {
                store.commit('resetState');
            }
            next();
        }
    },
    {
        path: '/test/dialogs',
        name: 'dialog-test',
        component: () => import('@/pages/test/DialogTest.vue'),
        meta: {
            title: 'Dialog Test Page'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    
    publicPath: '/',
    routes,
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        // For genre changes within explore page, maintain scroll position
        if (to.name === 'explore' && from.name === 'explore') {
            return false;
        }
        // For all other navigation, scroll to top
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const isLoggedIn = !!accessToken;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (accessToken && refreshToken && isLoggedIn) {
        store.commit('SAVE_TOKENS', {token: accessToken, refresh_token: refreshToken});
    } else if (!accessToken && requiresAuth) {
        console.log('Protected route accessed without auth - logging out');
        await store.dispatch('LOGOUT');
    }

    if (store.getters['PROFILE'] === null && store.getters['AUTH_DATA'].accessToken) {
        await store.dispatch('GET_PROFILE');
    }

    await store.dispatch('CHECK_TOKEN_ALIVE');

    if (!store.getters['IS_TOKEN_ACTIVE']) {
        const refreshToken = store.getters['AUTH_DATA'].refreshToken || localStorage.getItem('refreshToken');
        const accessToken = store.getters['AUTH_DATA'].accessToken || localStorage.getItem('accessToken');

        if (accessToken) {
            await store.dispatch('REFRESH_TOKEN', refreshToken);
        }
    }

    const isAuthenticated = store.getters['AUTH_DATA'].accessToken !== '';

    if (requiresAuth) {
        if (!isAuthenticated) {
            return next({
                path: '/login',
                query: {redirect: to.fullPath}
            });
        }

        if (to.meta?.roles && !isGranted(to.meta.roles, store.getters['AUTH_DATA']['userRoles'])) {
            return next({name: '404'});
        }

        if (to.matched.some((record) => record.meta.requiresVerify)) {
            const userVerify = store.getters['AUTH_DATA'].verify;

            if (userVerify === false) {
                return next({name: 'email-verification'});
            }
        }
    }

    if (to.meta?.human) {
        if (from.name) {
            keepRouteParams(to.path, JSON.stringify(to.params));
        } else {
            takeRouteParams(to);
        }
    }

    let listPages = [
        'private-profile',

        // dashboard
        'overview',
        'manageFollowers',
        'earnings',
        'accounts',
        'uploads',
        'manageEvents',
        'merch',
        'orders',
        'promotions',

        'library',
        'events',
        'followingUsers',

        // Account Settings
        'security',
        'privacy',
        'notifications',
        'payments',
        'subscriptions',

        'createMusicRelease',
        'createEvent',

        'checkOut'
    ];
    if (listPages.includes(to.name)) {
        store.commit('SET_RESET_SCROLL', null);
    }

    next();
});

router.afterEach((to, from) => {
    store.commit('CHANGE_ROUTE', {from: from, to: to});

    if (to.name !== 'login' && to.name !== 'logged-out') {
        store.commit('SET_NEED_LOGIN_REDIRECT_PATH', to.path);
    }
});

function isGranted(grantedRoles, userRoles) {
    if (!Array.isArray(grantedRoles) || !Array.isArray(userRoles)) {
        return false;
    }

    return userRoles.some((role) => grantedRoles.includes(role));
}

export default router;