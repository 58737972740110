<template>
	<div class="wrap_filter_bar_albums" :class="{'full': filterName === 'explore'}">

		<div class="filter_bar_" v-if="filterName === 'library' || STATUS_FILTER" :class="filterName">

			<div class="filters_">

				<!-- GENRE -->
				<div class="genre">
					<div class="material_input">
						<input type="text" readonly required id="btn-open-genres" v-model="GENRE" @click="handleInputClick('genre')">
						<label>Genre</label>
					</div>
				</div>

				<!-- SUB-GENRE -->
				<div class="subgenre">
					<div class="material_input">
						<v-menu
							v-model="menuOpen"
							offset-y
							:close-on-content-click="false"
							:max-width="300"
							content-class="sub-genre-menu-popup"
							:min-width="250">

							<template v-slot:activator="{ on, attrs }">
								<template v-if="filterName && filterName === 'explore'">
									<input type="text" required v-bind="attrs" v-on="on" v-model="SUBGENRE.name" @click="handleInputClick('subgenre')" @keydown.prevent>
								</template>
								<template v-if="filterName && filterName === 'library'">
									<input type="text" required v-bind="attrs" v-on="on" v-model="librarySubgenre.name" @click="handleInputClick('subgenre')" @keydown.prevent>
								</template>
							</template>

							<v-card class="sub-genre-menu elevation-8" rounded="lg" style="border: 1px solid rgba(255, 255, 255, 0.12);">
								<v-list max-height="400" class="overflow-y-auto" rounded="lg">
									<v-list-item
										v-for="item of SUBGENRES"
										:key="item.id"
										@click="selectSubgenre(item)"
										class="rounded-0"
										:class="{
											'v-list-item--active primary': isSelectedSubgenre(item)
										}"
										:ripple="{ class: 'primary--text' }"
										dense
										hover>
										<v-list-item-content>
											<div class="d-flex justify-space-between align-center">
												<span class="text-subtitle-1 font-weight-medium">{{ item.name }}</span>
												<v-chip
													v-if="filterName === 'explore'"
													x-small
													label
													class="ml-3"
													:color="isSelectedSubgenre(item) ? 'white' : 'primary'"
													:text-color="isSelectedSubgenre(item) ? 'primary' : 'white'"
													>
													{{ item.musicReleasesCount }}
												</v-chip>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>
						<label>Sub-Genre</label>
					</div>
					<div class="clear_subgenre" v-if="(filterName === 'explore' && SUBGENRE.name) || (filterName === 'library' && librarySubgenre.name)" @click="clearSubgenre">
						<span class="material-icons">cancel</span>
					</div>
				</div>

				<!-- BPM -->
				<div class="bpm">
					<v-menu v-model="bpmOptions.show"
							:close-on-content-click="false"
							:min-width="310"
							offset-y
							content-class="bpm-menu-popup"
							:allow-overflow="false">

						<template v-slot:activator="{ on, attrs }">
							<div class="material_input" v-bind="attrs" v-on="on">
								<input v-on:keypress="readonly"
									   v-if="BPM.length && filterName === 'explore' && !bpmOptions.show"
									   type="text"
									   required
									   :value="BPM[0] +' - '+ BPM[1]"
									   @click="handleInputClick('bpm')"
									   @keydown.prevent>

								<input v-on:keypress="readonly"
									   v-if="libraryBPM.length && filterName === 'library' && !bpmOptions.show"
									   type="text"
									   required
									   :value="libraryBPM[0] +' - '+ libraryBPM[1]"
									   @click="handleInputClick('bpm')"
									   @keydown.prevent>

								<input v-on:keypress="readonly"
									   v-if="bpmOptions.show"
									   type="text"
									   required
									   :value="bpmRange[0] + ' - ' + bpmRange[1]"
									   @click="handleInputClick('bpm')"
									   @keydown.prevent>

								<input v-on:keypress="readonly"
									   v-else
									   type="text"
									   required
									   @click="handleInputClick('bpm')"
									   @keydown.prevent>
								<label>BPM</label>
							</div>
						</template>

						<div class="bpm_body" v-click-outside="handleBpmClickOutside">
							<div class="wrap_slider">
								<div class="slider_container" ref="sliderContainer" @mousedown="startSliderDrag" @touchstart.prevent="startTouchDrag">
									<div class="slider_track" :style="{ left: `${minThumbPosition}%`, width: `${maxThumbPosition - minThumbPosition}%` }"></div>
									<div class="slider_thumb" :style="{ left: `${minThumbPosition}%` }" @mousedown.stop="startMinDrag" @touchstart.prevent.stop="startMinDrag"></div>
									<div class="slider_thumb" :style="{ left: `${maxThumbPosition}%` }" @mousedown.stop="startMaxDrag" @touchstart.prevent.stop="startMaxDrag"></div>
									<div class="slider_markers">
										<div class="marker" v-for="value in [0, 75, 150, 225, 300]" :key="value" :style="{ left: `${(value / 300) * 100}%` }">
											<span class="marker_line"></span>
											<span class="marker_value">{{ value }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="previous_selections" v-if="previousBpmSelections && previousBpmSelections.length">
								<div 
									v-for="(selection, index) in previousBpmSelections" 
									:key="index"
									class="selection" 
									@click="applyPreviousSelection(selection)"
								>
									{{selection[0]}} - {{selection[1]}}
								</div>
							</div>
						</div>

					</v-menu>

					<!-- Clear button for applied BPM -->
					<div class="bpm_action" v-if="!bpmOptions.show && ((filterName === 'explore' && BPM.length) || (filterName === 'library' && libraryBPM.length))" style="width: 78px; justify-content: flex-end;">
						<button type="button" class="clear_close" @click="cancelBPM">
							<span class="material-icons">cancel</span>
						</button>
					</div>
					<!-- Action buttons during BPM selection -->
					<div class="bpm_action" v-if="bpmOptions.show" style="width: 78px; justify-content: flex-end;">
						<button type="button" class="clear_close" @click="cancelBPM">
							<span class="material-icons">cancel</span>
						</button>
						<button type="button" class="apply_bpm active" @click="applyBpm">
							<span class="material-icons">check_circle</span>
						</button>
					</div>
				</div>

				<!-- KEYS -->
				<div class="key">
					<div class="material_input" @click="handleInputClick('key')" id="btn-open-keys">
						<input type="text" v-if="filterName === 'explore'" required v-model="combinedKeys">
						<input type="text" v-if="filterName === 'library'" required v-model="combinedLibraryKeys">
						<label>Key</label>
					</div>
					<!-- Clear button for applied keys -->
					<div class="key_action" v-if="!viewKeysFilter && ((filterName === 'explore' && APPLY_KEYS.length) || (filterName === 'library' && libraryKeys.length))" style="width: 78px; justify-content: flex-end;">
						<button type="button" class="clear_close" @click="clearKeys">
							<span class="material-icons">cancel</span>
						</button>
					</div>
					<!-- Existing action buttons during key selection -->
					<div class="key_action" v-if="keysFilter && viewKeysFilter">
						<button type="button" class="clear_close" @click="clearCloseKeys">
							<span class="material-icons">cancel</span>
						</button>
						<button v-if="filterName === 'explore'" type="button" class="apply_keys" :class="{'active': KEYS.length}" @click="applyKeys">
							<span class="material-icons">check_circle</span>
						</button>
						<button v-if="filterName === 'library'" type="button" class="apply_keys" :class="{'active': libraryKeys.length}" @click="applyKeys">
							<span class="material-icons">check_circle</span>
						</button>
					</div>
				</div>
			</div>

			<!-- TAGS -->
			<template v-if="filterName === 'explore'">
				<div class="tags_input_" v-if="showTagsInput || actions === false || TAGS.length">
					<div class="tags">
						<v-chip
							v-for="(item, index) in TAGS"
							:key="index"
							close
							@click:close="TAGS.splice(index,1)">
							{{ item }}
						</v-chip>
						<input type="text" placeholder="#Tags" @keypress="updateTags($event)" v-model="newTag" @keydown.delete="removeTags($event)">
					</div>
				</div>
			</template>
			<template v-if="filterName === 'library'">
				<div class="tags_input_" v-if="showTagsInput || actions === false || libraryTags.length">
					<div class="tags">
						<v-chip
							v-for="(item, index) in libraryTags"
							:key="index"
							close
							@click:close="libraryTags.splice(index,1)">
							{{ item }}
						</v-chip>
						<input type="text" placeholder="#Tags" @keypress="updateTags($event)" v-model="newTag" @keydown.delete="removeTags($event)">
					</div>
				</div>
			</template>
			<keys v-if="viewKeysFilter" class="filter_keys" :filterName="filterName"></keys>
		</div>

	</div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import keys from "../keys";
import eventBus from "@/eventBus";
import { directive as clickOutside } from 'vue-click-outside'

export default {
	name: "albumsFilter",
	components: {
		keys
	},
	props: ['actions', 'subgenreFilter', 'keysFilter', 'filterName', 'subgenreClear'],
	data() {
		return {
			GENRE: 'Psychedelic Trance',
			bpmOptions: {
				show: false,
				min: 0,
				max: 300,
			},
			bpmRange: [0, 300],
			previousBpmSelections: [
				[120, 135],
				[135, 145],
				[145, 155]
			],
			tags: [],
			newTag: '',
			actionsButtons: false,
			showTagsInput: false,
			filters: [],
			userID: null,
			dialogSaveFilterVisible: false,
			dialogNeedLogin: false,
			viewKeysFilter: false,
			bookmarkStatus: false,

			// saved filters
			menuSaveFilterVisible: false,
			newFilterName: '',
			activeSavedFilter: '',
			deleteSavedFilterStatus: false,
			saveSavedFilterStatus: true,
			discovery: false,
			subgenreSearch: '',
			menuOpen: false,
			minBpm: 0,
			maxBpm: 999,
			isDragging: false,
			activeThumb: null,
			minThumbPosition: 0,
			maxThumbPosition: 100,
			wasManuallyOpened: false
		};
	},
	watch: {
		TAGS() {
			if (!this.TAGS.length) {
				this.tags = [];
			}
		}
	},
	computed: {
		...mapGetters([
			'AUTH_DATA', 'PROFILE',
			'UPPER_FILTER',
			'GENRES', 'SUBGENRE', 'BPM', 'APPLY_KEYS', 'KEYS', 'TAGS',
			'CHANGE_FILTERS_STATUS', 'DISCOVERY',
			'STATUS_FILTER', 'ACTIVE_SAVED_FILTER', 'SAVED_FILTERS', 'SUBGENRES'
		]),
		...mapGetters({
			librarySubgenre: 'collection/SUBGENRE',
			libraryBPM: 'collection/BPM',
			libraryKeys: 'collection/KEYS',
			libraryTags: 'collection/TAGS',
		}),
		combinedKeys() {
			return this.KEYS.map(key => key.code).join(', ');
		},
		combinedLibraryKeys() {
			return this.libraryKeys.map(key => key.code).join(', ');
		},
		filteredSubgenres() {
			if (!this.subgenreSearch) return this.SUBGENRES;
			const search = this.subgenreSearch.toLowerCase();
			return this.SUBGENRES.filter(item => 
				item.name.toLowerCase().includes(search)
			);
		},
		isValidBPMRange() {
			return this.bpmRange[0] >= 0 && 
				   this.bpmRange[1] <= 300 && 
				   this.bpmRange[0] <= this.bpmRange[1];
		}
	},
	mounted() {
		// Load previous BPM selections from localStorage, or use defaults if none exist
		try {
			const savedSelections = localStorage.getItem('bpmSelections');
			if (savedSelections) {
				this.previousBpmSelections = JSON.parse(savedSelections);
			} else {
				// Save initial selections to localStorage
				localStorage.setItem('bpmSelections', JSON.stringify(this.previousBpmSelections));
			}
		} catch (e) {
			console.error('Failed to load BPM selections:', e);
		}

		if (!this.SUBGENRES.length && this.filterName === 'library') {
			this.getSubgenres();
		}

		// bpm
		if (this.filterName === 'explore') {
			if (this.BPM.length) {
				this.bpmRange = [...this.BPM];
				this.updateSlider();
			}
			if (this.TAGS.length) {
				this.tags = this.TAGS;
				this.$store.commit('SET_STATUS_FILTER', true);
			}
		}
		if (this.filterName === 'library') {
			if (this.libraryBPM.length) {
				this.bpmRange = [...this.libraryBPM];
				this.updateSlider();
			}
			if (this.libraryTags.length) {
				this.tags = this.libraryTags;
			}
		}

		// Add click outside handlers
		document.addEventListener('click', this.handleClickOutsideKeys);
		document.addEventListener('click', this.handleClickOutsideBpm);

		// Listen for BPM slider reset event
		eventBus.on('resetBpmSlider', this.resetBpmSlider);

		// Track if filter was manually opened
		this.wasManuallyOpened = this.STATUS_FILTER;

		// Add resize listener for filter bar visibility
		this.handleResize = () => {
			const width = window.innerWidth;
			const wasManuallyToggled = sessionStorage.getItem('filterManuallyToggled') === 'true';
			const wasAutoOpened = sessionStorage.getItem('filterAutoOpened') === 'true';
			
			if (width <= 767 && !this.STATUS_FILTER) {
				// Auto-open at small screens
				sessionStorage.setItem('filterAutoOpened', 'true');
				this.$store.commit('SET_STATUS_FILTER', true);
			} else if (width > 767 && !wasManuallyToggled && wasAutoOpened) {
				// Only auto-close if it was auto-opened and never manually toggled
				sessionStorage.removeItem('filterAutoOpened');
				this.$store.commit('SET_STATUS_FILTER', false);
			}
		};
		
		window.addEventListener('resize', this.handleResize);
		// Initial check
		this.handleResize();
	},
	beforeDestroy() {
		// Remove click outside handlers
		document.removeEventListener('click', this.handleClickOutsideKeys);
		document.removeEventListener('click', this.handleClickOutsideBpm);

		// Remove BPM slider reset event listener
		eventBus.off('resetBpmSlider', this.resetBpmSlider);

		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		...mapActions(['GET_PROFILE', 'CHANGE_FILTERS', 'UPDATE_DISCOVERY', 'GET_GENRES', 'GET_SUBGENRES', 'CLEAN_EXPLORE_GRID_ALBUMS']),
		toggle_discovery() {
			this.discovery = !this.discovery;
			this.$store.dispatch('UPDATE_DISCOVERY', this.discovery);
		},

		numbersOnly(evt) {
			console.log('click numbersOnly');
			// evt = (evt) ? evt : window.event;
			// const charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			//     evt.preventDefault();
			// } else {
			//     return true;
			// }
		},
		readonly(evt) {
			evt.preventDefault();
		},

		// value filters
		getSubgenres() {
			const subgenresParams = {
				genre: 2, // Psychedelic Trance
				'order[musicReleasesCount]': 'DESC'
			}
			this.GET_SUBGENRES(subgenresParams);
		},
		selectSubgenre(subgenre) {
			if (this.filterName === 'explore') {
				this.clearScrollPosition();
				this.$store.dispatch('UPDATE_SUBGENRE', subgenre);
				if (subgenre.name) {
					const subgenreName = subgenre.name.trim().replace(/ /g, '-');
					this.$router.replace({path: `/explore/${subgenreName}`});
				} else {
					this.$router.push({path: '/explore'});
				}
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_SUBGENRE', subgenre);
			}
			this.menuOpen = false;  // Close menu after selection
		},

		saveBPM() {
			if (!this.bpmRange[0]) {
				this.bpmRange[0] = 0;
			}
			if (!this.bpmRange[1]) {
				this.bpmRange[1] = 300;
			}

			if (this.filterName === 'explore') {
				this.$store.commit('SET_BPM', this.bpmRange);
			}
      console.log('this.filterName', this.filterName);
      if (this.filterName === 'library') {
				this.$store.commit('collection/SET_BPM', this.bpmRange);
			}
			this.bpmOptions.show = false;
      this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);


      eventBus.emit('customEvent');
		},
		cancelBPM() {
			// Check if we're already at default values (no actual filter applied)
			const isDefault = this.bpmRange[0] === 0 && this.bpmRange[1] === 300;
			const currentBpm = this.filterName === 'explore' ? this.BPM : this.libraryBPM;
			const hasActiveFilter = currentBpm.length > 0;

			// Only trigger state changes if we actually have an active filter
			if (hasActiveFilter) {
				if (this.filterName === 'explore') {
					this.$store.commit('SET_BPM', []);
					this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
					this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
					// Clean explore grid to force refresh
					this.$store.dispatch('CLEAN_EXPLORE_GRID_ALBUMS');
				} else if (this.filterName === 'library') {
					this.$store.commit('collection/SET_BPM', []);
				}
			}

			this.bpmRange = [0, 300];
			this.updateSlider();
			this.bpmOptions.show = false;
			
			// Only emit customEvent if we actually cleared an active filter
			if (hasActiveFilter) {
				eventBus.emit('customEvent');
			}
		},

		updateTags(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;

			// Enter || Space || Comma
			if (charCode === 13 || charCode === 32 || charCode === 44) {
				evt.preventDefault();
				if (this.filterName === 'explore') {
					if (this.newTag.length >= 1) {
						this.clearScrollPosition();
						this.tags.push(this.newTag);
						this.$store.commit('SET_TAGS', this.tags);
					}
				}
				if (this.filterName === 'library') {
					if (this.newTag.length >= 1) {
						this.libraryTags.push(this.newTag);
						this.$store.commit('collection/SET_TAGS', this.libraryTags);
					}
				}
				this.newTag = '';
			}

		},
		removeTags(ev) {
			if (ev.key === 'Backspace') {
				if (this.filterName === 'explore') {
					if (this.newTag.length) {
						return;
					} else if (!this.newTag.length) {
						this.tags.splice(-1, 1);
					}
				}
				if (this.filterName === 'library') {
					if (this.newTag.length) {
						return;
					} else if (!this.newTag.length) {
						this.libraryTags.splice(-1, 1);
					}
				}
			}
		},
		showKeys() {
			// Auto-apply BPM if it's open
			if (this.bpmOptions.show) {
				if (this.bpmRange[0] !== 0 || this.bpmRange[1] !== 300) {
					this.applyBpm();
				} else {
					this.clearBpm();
				}
			}
			this.viewKeysFilter = true;
		},
		clearCloseKeys() {
			this.actionsButtons = false;
			this.viewKeysFilter = false;

			if (this.filterName === 'explore') {
				this.$store.dispatch('UPDATE_KEYS', []);
				this.$store.commit('SET_APPLY_KEYS', []);
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_KEYS', []);
				this.$store.commit('collection/SET_APPLY_KEYS', []);
			}

			this.$store.commit('SET_ALBUM_EXPANDED', null);
		},
		applyKeys() {
			let currentKeys = this.filterName === 'explore' ? this.APPLY_KEYS : this.libraryKeys;
			let newKeys = this.filterName === 'explore' ? this.KEYS : this.libraryKeys;
			
			// Compare keys arrays
			let hasChanged = currentKeys.length !== newKeys.length || 
				currentKeys.some((key, index) => key.code !== newKeys[index].code);

			if (hasChanged) {
				if (this.filterName === 'explore') {
					this.clearScrollPosition();
					this.$store.commit('SET_APPLY_KEYS', this.KEYS);
				} else if (this.filterName === 'library') {
					this.$store.commit('collection/SET_APPLY_KEYS', this.libraryKeys);
				}
				eventBus.emit('customEvent');
			}

			this.viewKeysFilter = false;
		},
		clearSubgenre() {
			if (this.filterName === 'explore') {
				this.clearScrollPosition();
				this.$store.dispatch('UPDATE_SUBGENRE', {});
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
				// Clean explore grid to force refresh
				this.$store.dispatch('CLEAN_EXPLORE_GRID_ALBUMS');
				// Update route if on explore page
				this.$router.replace('/').catch(err => {
					if (err.name !== 'NavigationDuplicated') {
						throw err;
					}
				});
				// Emit customEvent to trigger data refresh
				eventBus.emit('customEvent');
			} else if (this.filterName === 'library') {
				this.$store.commit('collection/SET_SUBGENRE', '');
			}
		},
		isSelectedSubgenre(item) {
			if (this.filterName === 'explore') {
				return this.SUBGENRE.id === item.id;
			}
			if (this.filterName === 'library') {
				return this.librarySubgenre.id === item.id;
			}
			return false;
		},
		closeSubgenreMenu() {
			this.menuOpen = false;
		},
		startSliderDrag(e) {
			const container = this.$refs.sliderContainer
			const rect = container.getBoundingClientRect()
			const position = ((e.clientX - rect.left) / rect.width) * 100
			
			// If clicking directly on the track (not dragging a thumb)
			if (!e.target.classList.contains('slider_thumb')) {
				// Find the closest thumb to the click position
				if (Math.abs(position - this.minThumbPosition) < Math.abs(position - this.maxThumbPosition)) {
					this.activeThumb = 'min'
					this.minThumbPosition = position
					this.bpmRange[0] = Math.round((position / 100) * 300)
				} else {
					this.activeThumb = 'max'
					this.maxThumbPosition = position
					this.bpmRange[1] = Math.round((position / 100) * 300)
				}
				this.updateSlider()
				// Start dragging immediately after clicking
				this.startDrag()
				// Update position immediately
				this.updateThumbPosition(e)
				return
			}
			
			// Normal thumb drag handling
			if (Math.abs(position - this.minThumbPosition) < Math.abs(position - this.maxThumbPosition)) {
				this.activeThumb = 'min'
			} else {
				this.activeThumb = 'max'
			}
			
			this.updateThumbPosition(e)
			this.startDrag()
		},
		startMinDrag() {
			this.activeThumb = 'min'
			this.startDrag()
		},
		startMaxDrag() {
			this.activeThumb = 'max'
			this.startDrag()
		},
		startDrag() {
			this.isDragging = true
			window.addEventListener('mousemove', this.updateThumbPosition)
			window.addEventListener('mouseup', this.stopDrag)
		},
		stopDrag() {
			this.isDragging = false
			this.activeThumb = null
			window.removeEventListener('mousemove', this.updateThumbPosition)
			window.removeEventListener('mouseup', this.stopDrag)
		},
		updateThumbPosition(e) {
			if (!this.isDragging) return
			
			const container = this.$refs.sliderContainer
			const rect = container.getBoundingClientRect()
			let position = ((e.clientX - rect.left) / rect.width) * 100
			
			// Constrain position between 0 and 100
			position = Math.max(0, Math.min(100, position))
			
			if (this.activeThumb === 'min') {
				this.minThumbPosition = Math.min(position, this.maxThumbPosition - 1)
				this.bpmRange[0] = Math.round((this.minThumbPosition / 100) * 300)
			} else {
				this.maxThumbPosition = Math.max(position, this.minThumbPosition + 1)
				this.bpmRange[1] = Math.round((this.maxThumbPosition / 100) * 300)
			}
			this.updateSlider()
		},
		updateSlider() {
			this.minThumbPosition = (this.bpmRange[0] / 300) * 100
			this.maxThumbPosition = (this.bpmRange[1] / 300) * 100
		},
		clearBpm() {
			if (this.filterName === 'explore') {
				this.clearScrollPosition();
				this.$store.commit('SET_BPM', []);
			} else if (this.filterName === 'library') {
				this.$store.commit('collection/SET_BPM', []);
			}
			this.bpmRange = [0, 300];
			this.updateSlider();
			this.bpmOptions.show = false;
		},
		applyBpm() {
			// Ensure valid values
			if (!this.bpmRange[0]) {
				this.bpmRange[0] = 0;
			}
			if (!this.bpmRange[1]) {
				this.bpmRange[1] = 300;
			}

			// Get current BPM values based on filter name
			const currentBpm = this.filterName === 'explore' ? this.BPM : this.libraryBPM;
			
			// Check if values have actually changed
			const hasChanged = !currentBpm.length || 
				currentBpm[0] !== this.bpmRange[0] || 
				currentBpm[1] !== this.bpmRange[1];

			// Only update if values have changed
			if (hasChanged) {
				if (this.filterName === 'explore') {
					this.clearScrollPosition();
				}
				const newRange = [...this.bpmRange];
				if (this.filterName === 'explore') {
					this.$store.commit('SET_BPM', newRange);
				} else if (this.filterName === 'library') {
					this.$store.commit('collection/SET_BPM', newRange);
				}
				this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);
				
				// Save to previous selections if not default range
				if (!(newRange[0] === 0 && newRange[1] === 300)) {
					const newSelectionStr = JSON.stringify(newRange);
					const filtered = this.previousBpmSelections.filter(selection => 
						JSON.stringify(selection) !== newSelectionStr
					);
					
					this.previousBpmSelections = [newRange, ...filtered].slice(0, 3);
					
					try {
						localStorage.setItem('bpmSelections', JSON.stringify(this.previousBpmSelections));
					} catch (e) {
						console.error('Failed to save BPM selections:', e);
					}
				}
				
				eventBus.emit('customEvent');
			}
			
			this.bpmOptions.show = false;
		},
		clearKeys() {
			if (this.filterName === 'explore') {
				// Only trigger refresh if there were actually applied keys
				const hadAppliedKeys = this.APPLY_KEYS.length > 0;
				this.clearScrollPosition();
				this.$store.dispatch('UPDATE_KEYS', []);
				this.$store.commit('SET_APPLY_KEYS', []);
				if (hadAppliedKeys) {
					eventBus.emit('customEvent');
				}
			} else if (this.filterName === 'library') {
				// Only trigger refresh if there were actually applied keys
				const hadAppliedKeys = this.libraryKeys.length > 0;
				this.$store.commit('collection/SET_KEYS', []);
				this.$store.commit('collection/SET_APPLY_KEYS', []);
				if (hadAppliedKeys) {
					eventBus.emit('customEvent');
				}
			}
		},
		updateMinBpm(value) {
			const newValue = Math.max(0, Math.min(parseInt(value) || 0, this.bpmRange[1]))
			this.bpmRange = [newValue, this.bpmRange[1]]
			this.updateSlider()
		},
		updateMaxBpm(value) {
			const newValue = Math.max(this.bpmRange[0], Math.min(parseInt(value) || 300, 300))
			this.bpmRange = [this.bpmRange[0], newValue]
			this.updateSlider()
		},
		startTouchDrag(e) {
			const touch = e.touches[0]
			const container = this.$refs.sliderContainer
			const rect = container.getBoundingClientRect()
			const position = ((touch.clientX - rect.left) / rect.width) * 100
			
			// If touching directly on the track (not a thumb)
			if (!e.target.classList.contains('slider_thumb')) {
				// Find the closest thumb to the touch position
				if (Math.abs(position - this.minThumbPosition) < Math.abs(position - this.maxThumbPosition)) {
					this.activeThumb = 'min'
					this.minThumbPosition = position
					this.bpmRange[0] = Math.round((position / 100) * 300)
				} else {
					this.activeThumb = 'max'
					this.maxThumbPosition = position
					this.bpmRange[1] = Math.round((position / 100) * 300)
				}
				this.updateSlider()
			}
			
			// Start touch dragging
			this.isDragging = true
			window.addEventListener('touchmove', this.updateTouchPosition)
			window.addEventListener('touchend', this.stopDrag)
		},
		updateTouchPosition(e) {
			if (!this.isDragging) return
			
			const touch = e.touches[0]
			const container = this.$refs.sliderContainer
			const rect = container.getBoundingClientRect()
			let position = ((touch.clientX - rect.left) / rect.width) * 100
			
			// Constrain position between 0 and 100
			position = Math.max(0, Math.min(100, position))
			
			if (this.activeThumb === 'min') {
				this.minThumbPosition = Math.min(position, this.maxThumbPosition - 1)
				this.bpmRange[0] = Math.round((this.minThumbPosition / 100) * 300)
			} else {
				this.maxThumbPosition = Math.max(position, this.minThumbPosition + 1)
				this.bpmRange[1] = Math.round((this.maxThumbPosition / 100) * 300)
			}
			this.updateSlider()
		},
		startDrag() {
			this.isDragging = true
			window.addEventListener('mousemove', this.updateThumbPosition)
			window.addEventListener('mouseup', this.stopDrag)
			window.addEventListener('touchmove', this.updateTouchPosition)
			window.addEventListener('touchend', this.stopDrag)
		},
		stopDrag() {
			this.isDragging = false
			this.activeThumb = null
			window.removeEventListener('mousemove', this.updateThumbPosition)
			window.removeEventListener('mouseup', this.stopDrag)
			window.removeEventListener('touchmove', this.updateTouchPosition)
			window.removeEventListener('touchend', this.stopDrag)
		},
		handleBpmClickOutside(event) {
			if (this.bpmOptions.show) {
				const bpmElement = document.querySelector('.bpm_body');
				const bpmInput = document.querySelector('.bpm .material_input');
				
				if (bpmElement && !bpmElement.contains(event.target) && 
					!bpmInput.contains(event.target)) {
					// Get current BPM values based on filter name
					const currentBpm = this.filterName === 'explore' ? this.BPM : this.libraryBPM;
					
					// Check if values have actually changed and are not default
					const isDefault = this.bpmRange[0] === 0 && this.bpmRange[1] === 300;
					const hasChanged = !currentBpm.length || 
						currentBpm[0] !== this.bpmRange[0] || 
						currentBpm[1] !== this.bpmRange[1];

					// Only auto-apply if values are not default and have changed
					if (!isDefault && hasChanged) {
						const newRange = [...this.bpmRange];
						if (this.filterName === 'explore') {
							this.$store.commit('SET_BPM', newRange);
						} else if (this.filterName === 'library') {
							this.$store.commit('collection/SET_BPM', newRange);
						}
						this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);

						// Save to previous selections
						const newSelectionStr = JSON.stringify(newRange);
						const filtered = this.previousBpmSelections.filter(selection => 
							JSON.stringify(selection) !== newSelectionStr
						);
						
						// Add to beginning and keep only last 3
						this.previousBpmSelections = [newRange, ...filtered].slice(0, 3);
						
						// Save to localStorage
						try {
							localStorage.setItem('bpmSelections', JSON.stringify(this.previousBpmSelections));
						} catch (e) {
							console.error('Failed to save BPM selections:', e);
						}

						eventBus.emit('customEvent');
					}
					this.bpmOptions.show = false;
				}
			}
		},
		handleClickOutsideKeys(event) {
			if (this.viewKeysFilter) {
				const keysElement = document.querySelector('.filter_keys');
				const keysButton = document.getElementById('btn-open-keys');
				
				if (keysElement && !keysElement.contains(event.target) && 
					!keysButton.contains(event.target)) {
					if ((this.filterName === 'explore' && this.KEYS.length) || 
						(this.filterName === 'library' && this.libraryKeys.length)) {
						this.applyKeys();
					} else {
						this.clearCloseKeys();
					}
				}
			}
		},
		handleInputClick(inputType) {
			// Handle Keys auto-apply
			if (this.viewKeysFilter && inputType !== 'key') {
				let currentKeys = this.filterName === 'explore' ? this.APPLY_KEYS : this.libraryKeys;
				let newKeys = this.filterName === 'explore' ? this.KEYS : this.libraryKeys;
				
				// Compare keys arrays
				let hasChanged = currentKeys.length !== newKeys.length || 
					currentKeys.some((key, index) => key.code !== newKeys[index].code);

				if (hasChanged) {
					if (this.filterName === 'explore') {
						this.$store.commit('SET_APPLY_KEYS', this.KEYS);
					} else if (this.filterName === 'library') {
						this.$store.commit('collection/SET_APPLY_KEYS', this.libraryKeys);
					}
					eventBus.emit('customEvent');
				}
				this.viewKeysFilter = false;
			}

			// Handle BPM auto-apply
			if (this.bpmOptions.show && inputType !== 'bpm') {
				const currentBpm = this.filterName === 'explore' ? this.BPM : this.libraryBPM;
				
				// Only auto-apply if values are not default and have changed
				const isDefault = this.bpmRange[0] === 0 && this.bpmRange[1] === 300;
				const hasChanged = !currentBpm.length || 
					currentBpm[0] !== this.bpmRange[0] || 
					currentBpm[1] !== this.bpmRange[1];

				if (!isDefault && hasChanged) {
					const newRange = [...this.bpmRange];
					if (this.filterName === 'explore') {
						this.$store.commit('SET_BPM', newRange);
					} else if (this.filterName === 'library') {
						this.$store.commit('collection/SET_BPM', newRange);
					}
					this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);

					// Save to previous selections
					const newSelectionStr = JSON.stringify(newRange);
					const filtered = this.previousBpmSelections.filter(selection => 
						JSON.stringify(selection) !== newSelectionStr
					);
					
					// Add to beginning and keep only last 3
					this.previousBpmSelections = [newRange, ...filtered].slice(0, 3);
					
					// Save to localStorage
					try {
						localStorage.setItem('bpmSelections', JSON.stringify(this.previousBpmSelections));
					} catch (e) {
						console.error('Failed to save BPM selections:', e);
					}

					eventBus.emit('customEvent');
				}
				this.bpmOptions.show = false;
			}

			// Handle specific input actions
			switch(inputType) {
				case 'bpm':
					this.viewKeysFilter = false;
					break;
				case 'subgenre':
					this.viewKeysFilter = false;
					this.bpmOptions.show = false;
					break;
				case 'genre':
					this.viewKeysFilter = false;
					this.bpmOptions.show = false;
					break;
				case 'key':
					this.bpmOptions.show = false;
					this.viewKeysFilter = !this.viewKeysFilter;
					break;
			}
		},
		resetBpmSlider() {
			this.bpmRange = [0, 300];
			this.updateSlider();
		},
		applyPreviousSelection(selection) {
			this.bpmRange = [...selection];
			this.updateSlider();
			this.applyBpm();
		},
		saveFilterState() {
			if (this.filterName === 'explore') {
				const filterState = {
					bpm: this.BPM,
					keys: this.APPLY_KEYS,
					tags: this.TAGS,
					subgenre: this.SUBGENRE,
					scrollPosition: window.scrollY,
					albumId: this.GRID_ALBUM_SCROLL_ID
				};
				sessionStorage.setItem('exploreFilterState', JSON.stringify(filterState));
				console.log('Saved filter state with scroll position:', filterState);
			}
		},
		clearScrollPosition() {
			console.log('Clearing filter state and scroll position');
			sessionStorage.removeItem('exploreFilterState');
			this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', null);
		},
		toggleFilter() {
			const newState = !this.STATUS_FILTER;
			// Track that user manually toggled the filter
			sessionStorage.setItem('filterManuallyToggled', 'true');
			// Clear the auto-opened state since user took manual control
			sessionStorage.removeItem('filterAutoOpened');
			this.$store.commit('SET_STATUS_FILTER', newState);
		}
	}
};
</script>
