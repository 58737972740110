<template>
  <div>
    <v-bottom-sheet 
      :content-class="'sub_genres_wrap'" 
      v-model="localDialog">
      <template v-slot:activator="{ on }">
        <div class="mobile_filter_item" v-on="on">
          <span class="top">BPM</span>
          <span class="bottom">{{displayValue}}</span>
        </div>
      </template>
      <v-sheet 
        class="text-left subGenresDialog"
        :style="{ transform: `translateY(${sheetTransform}px)`, transition: isDragging ? 'none' : 'transform 0.3s ease' }"
      >
        <div class="style_dialog subGenres bpm-dialog"
             @touchstart.passive="handleTouchStart"
             @touchmove.passive="handleTouchMove"
             @touchend.passive="handleTouchEnd"
             @click.stop>
          <div class="sheet-handle"></div>
          <h2 class="header">
            BPM
          </h2>
          <div class="subGenresItems bpm-wrap">
            <v-list>
              <div class="wrap_inputs">
                <input type="text" v-model="localRange[0]" maxlength="3">
                <span>-</span>
                <input type="text" v-model="localRange[1]" maxlength="3">
              </div>

              <div class="wrap_slider">
                <v-range-slider
                    v-model="localRange"
                    :min="0"
                    :max="300"
                    :step="1"
                    hide-details
                    :thumb-color="'#7481A6'"
                    :thumb-size='20'
                    :tick-size='100'
                    class="align-center"
                    @start="onSliderStart"
                    @end="onSliderEnd">
                </v-range-slider>
                <div class="slider_markers">
                  <div class="marker" v-for="value in [0, 75, 150, 225, 300]" :key="value" :style="{ left: `${(value / 300) * 100}%` }">
                    <span class="marker_line"></span>
                    <span class="marker_value">{{ value }}</span>
                  </div>
                </div>
              </div>
            </v-list>
          </div>

          <div class="modal_wrap_actions">
            <button type="button" class="unsubscribe" v-ripple @click="cancelBPM">Clear</button>
            <button type="button" class="keep_it" v-ripple @click="saveBPM">Apply</button>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'BpmFilterCard',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localDialog: false,
      localRange: [0, 300],
      touchStartY: 0,
      sheetTransform: 0,
      isDragging: false,
      initialSheetHeight: 0,
      scrollPosition: 0
    };
  },
  computed: {
    displayValue() {
      return this.value.length > 0 ? `${this.value[0]} - ${this.value[1]}` : '-';
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.localRange = newValue.length > 0 ? newValue : [0, 300];
      },
      immediate: true
    },
    dialog(newValue) {
      this.localDialog = newValue;
      if (newValue) {
        this.resetSheet();
      }
    },
    localDialog(newValue) {
      this.$emit('update:dialog', newValue);
      if (newValue) {
        this.resetSheet();
        // Smooth scroll to top when opening
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  },
  methods: {
    onSliderStart() {
      const bottomSheet = document.querySelector('.v-bottom-sheet');
      if (bottomSheet) {
        bottomSheet.style.touchAction = 'none';
      }
    },
    onSliderEnd() {
      const bottomSheet = document.querySelector('.v-bottom-sheet');
      if (bottomSheet) {
        bottomSheet.style.touchAction = '';
      }
    },
    saveBPM() {
      this.$emit('input', this.localRange);
      this.localDialog = false;
    },
    cancelBPM() {
      this.localRange = [0, 300];
      this.$emit('input', []);
      this.localDialog = false;
    },
    resetSheet() {
      this.sheetTransform = 0;
      this.isDragging = false;
      this.initialSheetHeight = 0;
    },
    handleTouchStart(e) {
      if (e.target.closest('input') || e.target.closest('.wrap_slider')) {
        return;
      }
      
      const sheet = e.target.closest('.style_dialog');
      if (sheet) {
        this.initialSheetHeight = sheet.getBoundingClientRect().height;
      }
      
      this.isDragging = true;
      this.touchStartY = e.touches[0].clientY;
      this.sheetTransform = 0;
    },
    handleTouchMove(e) {
      if (!this.isDragging) return;
      
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - this.touchStartY;
      
      if (deltaY > 0) {
        const resistance = 0.7;
        const resistedDelta = deltaY * resistance;
        this.sheetTransform = resistedDelta;
      }
    },
    handleTouchEnd() {
      if (!this.isDragging) return;
      
      const threshold = this.initialSheetHeight * 0.2;
      const velocity = Math.abs(this.sheetTransform / 16);
      
      if (this.sheetTransform > threshold || velocity > 10) {
        this.localDialog = false;
      } else {
        requestAnimationFrame(() => {
          this.sheetTransform = 0;
        });
      }
      
      this.isDragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/components/bpmFilterCard';

.v-bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.wrap_slider {
  touch-action: none !important;
}
</style>