<template>
  <div class="item_track" :class="[{ 
    selected: CURRENT_TRACK !== null && CURRENT_TRACK.id && track.id === CURRENT_TRACK.id && PLAYING,
    'highlight-recent': highlightRecent
  }]" :ref="`track-${track.id}`" @click="mobile && playTrack(track, index)">
    <div class="_left_">
      <div class="play_pause">
        <div class="track_deleted_block" v-if="track.deletedAt"></div>
        <template v-else>
          <template v-if="track.link">
            <button v-if="CURRENT_TRACK !== null && CURRENT_TRACK.id && track.id === CURRENT_TRACK.id && PLAYING" class="pause" @click="pauseTrack($event)" v-ripple>
              <span class="material-icons">pause</span>
              <div class="index"></div>
            </button>
            <button v-else class="play" @click="playTrack(track, index)" v-ripple>
              <span class="material-icons">play_arrow</span>
            </button>
          </template>
          <template v-else>
            <v-tooltip top close-delay="0" transition="false">
              <template v-slot:activator="{ on, attrs }">
                <div class="track_error" v-bind="attrs" v-on="on"></div>
              </template>
              <div>Unable to play at this time</div>
            </v-tooltip>
          </template>
        </template>

        <v-progress-circular
          :class="'track_progress'"
          v-if="CURRENT_TRACK !== null && CURRENT_TRACK.id === track.id"
          :rotate="-90"
          :size="35"
          :width="2"
          :value="Math.round(PROGRESS)"
          color="#20C4F5">
        </v-progress-circular>
      </div>

      <div class="track_deleted" v-if="track.deletedAt" @click="deleteTrack(track)"></div>
      <template v-else>
        <div class="track_purchased" v-if="track.isPurchased && track.isPurchased === true"></div>
        <template v-else>
          <template v-if="hideLike === true">
            <div class="emulat_like"></div>
          </template>
          <template v-else>
            <button type="button" class="like" v-if="!track.isFavorite || track.isFavorite === false" @click="addTrackToFavorites(track, track.playLists[0].musicRelease)"></button>
            <button type="button" class="like active" v-if="track.isFavorite === true" @click="removeTrackFromFavorites(track, track.playLists[0].musicRelease)"></button>
          </template>
        </template>
      </template>

      <div class="names_container">
        <div v-if="track.playLists && track.playLists[0] && track.playLists[0].musicRelease" class="track_name" @click="!mobile && redirectToAlbum(track.playLists[0].musicRelease.id, track.playLists[0].musicRelease.identifier)">
          {{ track.title }}
        </div>
        <div v-else class="track_name">{{ track.title }}</div>

        <div class="artist_name" v-if="track.artist" @click="!mobile && redirectToArtistPage(track.artist.id, track.artist.identifier)">
          {{ track.artist.name }}
        </div>
        <div v-else class="artist_name">-</div>
      </div>

      <div class="track_released" v-if="SHOW_COLUMNS_TRACK.includes('released')">
        {{ track.publishedAt | moment("MMM YYYY") }}
      </div>

      <div class="track_bpm" v-if="SHOW_COLUMNS_TRACK.includes('bpm')">{{ track.bpm }}</div>
      <div class="track_key" v-if="SHOW_COLUMNS_TRACK.includes('key')">
        <template v-if="track.key && track.key.code">{{ track.key.code }}</template>
        <template v-else>-</template>
      </div>
    </div>

    <div class="_right_">
      <div class="track_deleted_block" v-if="track.deletedAt"></div>

      <template v-else>
        <template v-if="track.isPurchased !== true">
          <template v-if="track.inCart === true">
            <button type="button" class="track_paid track_in_cart" @click="openCart" id="open-header-shopping-cart" v-ripple>
              <span class="in_cart">In Cart</span>
            </button>
          </template>
          <template v-else>
            <button type="button" class="track_paid" v-if="track.price > 0" v-ripple>
              <span class="price">${{ track.price }}</span>
              <span class="add_to_cart" @click="setYouAlbumPrice(track, 'buy')">Add to Cart</span>
            </button>

            <button type="button" class="track_syp" v-if="track.price === 0 && track.inCart === false" v-ripple @click="setYouAlbumPrice(track, 'set')">
              <span class="default_name">SYP</span>
              <span class="full_name">Set your price</span>
            </button>
          </template>
        </template>
        <template v-if="track.isPurchased && track.isPurchased === true || !track.price && track.price !== 0">
          <a class="download_track" v-ripple @click.prevent="checkPossibilityDownloadTrack(track)">
            <span>Download</span>
          </a>
        </template>
      </template>

      <v-menu
        transition="slide-y-transition"
        :close-on-content-click="false"
        :nudge-width="120"
        content-class="menu-pointer charcoal"
        offset-y
        nudge-left="60"
        nudge-bottom="15"
        origin="top center">
        <template v-slot:activator="{ on, attrs }">
          <button type="button" v-ripple class="more" v-bind="attrs" v-on="on"></button>
        </template>
        <v-list>
          <v-list-item v-ripple>
            <button type="button" @click="openComingSoonModal">Add to queue</button>
          </v-list-item>
          <v-list-item v-ripple>
            <button type="button" @click="openComingSoonModal">Play next</button>
          </v-list-item>
          <v-list-item v-ripple>
            <button type="button" @click="openComingSoonModal">Add to Playlist</button>
          </v-list-item>
          <v-list-item v-ripple>
            <button type="button" @click="openComingSoonModal">Report</button>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
    <downloadTrackDialog v-if="downloadMessageDialogVisible" v-model="downloadMessageDialogVisible"></downloadTrackDialog>
    <setYouAlbumPrice v-if="setYouAlbumPriceDialogVisible" v-model="setYouAlbumPriceDialogVisible" :song="setYouSong" :album="track.playLists[0].musicRelease" :setAlbumYourPriceModalVariant="setAlbumYourPriceModalVariant" :type="'song'"></setYouAlbumPrice>
    <errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
    <comingSoonDialog v-model="showComingSoonDialog"></comingSoonDialog>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import dialogNeedLogin from '@/components/dialogs/needLogin';
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';  // Adjust the path as necessary

const downloadTrackDialog = () => import('@/components/dialogs/downloadTrack.vue');
const setYouAlbumPrice = () => import('@/components/dialogs/setYouAlbumPrice');
const errorDialog = () => import('@/components/dialogs/error');

import addTrackToFavorites from '@/mixins/musicLike/addTrackToFavorites';
import removeTrackFromFavorites from '@/mixins/musicLike/removeTrackFromFavorites';
import isMobile from '@/mixins/isMobile';

export default {
  name: 'itemTrack',
  props: ['track', 'index', 'hideLike', 'playList'],
  components: {
    dialogNeedLogin,
    downloadTrackDialog,
    setYouAlbumPrice,
    errorDialog,
    comingSoonDialog  // Ensure this is included
  },
  data() {
    return {
      needLoginDialog: {
        action: null,
        visible: false,
      },
      downloadMessageDialogVisible: false,
      setAlbumYourPriceModalVariant: null,
      setYouAlbumPriceDialogVisible: false,
      setYouSong: null,
      errorDialog: false,
      showComingSoonDialog: false,
      highlightRecent: false
    }
  },
  mixins: [addTrackToFavorites, removeTrackFromFavorites, isMobile],
  computed: {
    ...mapGetters([
      'AUTH_DATA', 'CURRENT_TRACK', 'PLAYING', 'CURRENT_ALBUM', 'SHOW_COLUMNS_TRACK', 'TRACKS', 'PROGRESS',
      'SONG_DOWNLOAD_DATA', 'DOWNLOAD_SONG_LINK', 'FAVOURITE_TRACKS', 'SONG_DOWNLOADED', 'TRACK_SCROLL_ID'
    ]),
    ...mapGetters({
      collectionTracks: 'collection/TRACKS'
    })
  },
  mounted() {
    const currentTrack = this.$refs[`track-${this.TRACK_SCROLL_ID}`];
    let currentRoute = this.$router.history.current.name;
    console.log('currentRoute', currentRoute);

    if (currentTrack && (currentRoute === 'explore' || currentRoute === 'subgenre' || currentRoute === 'searchDetails')) {
      this.$nextTick(() => {
        currentTrack.scrollIntoView({
          block: 'center'
        });

        const lastInteractedId = sessionStorage.getItem('lastInteractedElement');
        if (lastInteractedId && lastInteractedId === `track-${this.track.id}`) {
          this.highlightRecent = true;
          setTimeout(() => {
            this.highlightRecent = false;
            sessionStorage.removeItem('lastInteractedElement');
          }, 2000);
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'SET_PLAY', 'SET_PAUSE',
      'REMOVE_TRACK_FROM_FAVORITES', 'ADD_TRACK_TO_FAVORITES',
      'CHECK_SONG_DOWNLOAD', 'GET_DOWNLOAD_SONG_LINK',
      'ADD_TO_BASKET'
    ]),
    openComingSoonModal() {
      this.showComingSoonDialog = true;
    },
    redirectToArtistPage(artistId, artistName) {
      sessionStorage.setItem('lastInteractedElement', `track-${this.track.id}`);
      this.$store.commit('SET_TRACK_SCROLL_ID', this.track.id);
      this.$router.push({ name: 'artist-profile', params: { id: artistId, name: window.toHumanFormat(artistName) } });
    },
    redirectToAlbum(albumID, albumName) {
      sessionStorage.setItem('lastInteractedElement', `track-${this.track.id}`);
      this.$store.commit('SET_TRACK_SCROLL_ID', this.track.id);
      this.$router.push({ name: 'albumDetails', params: { id: albumID, name: window.toHumanFormat(albumName) } });
    },
    addSongToCart(song) {
      if (this.AUTH_DATA.accessToken) {
        song.inCart = true;
        let data = {
          productId: song.id,
          type: 'song'
        };
        this.ADD_TO_BASKET(data)
          .then(() => { })
          .catch(err => console.error(`addSongToCart, ${err}`))
      } else {
        this.needLoginDialog = {
          action: 'add_to_cart',
          visible: true,
        }
      }
    },
    openCart() {
      if (this.AUTH_DATA.accessToken) {
        this.$store.commit('SET_HEADER_SHOPPING_CART', true);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        this.needLoginDialog = {
          action: 'open_cart',
          visible: true,
        }
      }
    },
    setYouAlbumPrice(song, variant) {
      if (this.AUTH_DATA.accessToken) {
        this.setAlbumYourPriceModalVariant = variant;
        this.setYouAlbumPriceDialogVisible = true;
        this.setYouSong = song;
      } else {
        this.needLoginDialog = {
          action: 'set_you_price',
          visible: true,
        }
      }
    },
    playTrack(track, index) {
      if (this.playList === 'defaultTrackList') {
        this.$store.dispatch('SET_TRACK_LIST', this.TRACKS);
      }
      if (this.playList === 'favoriteTrackList') {
        this.$store.dispatch('SET_TRACK_LIST', this.FAVOURITE_TRACKS);
      }
      if (this.playList === 'libraryTrackList') {
        this.$store.dispatch('SET_TRACK_LIST', this.collectionTracks);
      }
      this.SET_PLAY({
        fromPlay: 'track_default',
        track: track,
        index: index,
        album: track.playLists[0].musicRelease
      });
    },
    pauseTrack(event) {
      event.stopPropagation();
      this.SET_PAUSE();
    },
    checkPossibilityDownloadTrack(track) {
      let trackID = track.id;
      let trackArtist = '';
      if (track.artist && track.artist.name) {
        trackArtist = track.artist.name;
      } else {
        trackArtist = '';
      }
      let trackTitle = track.title;

      if (this.AUTH_DATA.accessToken) {
        this.$store.commit('SET_SONG_DOWNLOAD_DATA', { id: trackID, artist: trackArtist, title: trackTitle });
        if (track.isPurchased === true) {
          this.directDownload();
        } else {
          this.CHECK_SONG_DOWNLOAD()
            .then(() => {
              let remain = this.SONG_DOWNLOADED.limit - this.SONG_DOWNLOADED.downloaded;
              if (remain <= 10) {
                this.downloadMessageDialogVisible = true;
              } else {
                this.directDownload();
              }
            })
            .catch(err => console.error(`checkPossibilityDownloadTrack, ${err}`));
        }
      } else {
        this.needLoginDialog = {
          action: 'download_track',
          visible: true,
        }
      }
    },
    directDownload() {
      this.GET_DOWNLOAD_SONG_LINK(this.SONG_DOWNLOAD_DATA.id)
        .then(() => {
          if (this.DOWNLOAD_SONG_LINK) {
            let link = document.createElement('a');
            link.href = this.DOWNLOAD_SONG_LINK;
            link.download = `${this.SONG_DOWNLOAD_DATA.artist} - ${this.SONG_DOWNLOAD_DATA.name}.flac`;
            link.click();
          } else {
            this.errorDialog = true;
          }
        })
        .catch(err => console.error(`checkPossibilityDownloadTrack, ${err}`));
    },
    deleteTrack(track) {
      const trackID = track.id;
      this.removeTrackFromFavorites(track, track.playLists[0].musicRelease.id);
      this.$store.dispatch('REMOVE_TRACK_FROM_COLLECTIONS', trackID);
    }
  }
}
</script>


<style lang="scss" scoped>
@keyframes shineEffect {
  0% {
    transform: translateX(-100%) skewX(-15deg);
    opacity: 1;
  }
  99% {
    transform: translateX(200%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(200%) skewX(-15deg);
    opacity: 0;
  }
}

.item_track.highlight-recent {
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(32, 196, 245, 0.3),
      transparent
    );
    z-index: 2;
    animation: shineEffect 1.5s ease-in-out forwards;
  }
}
</style>
