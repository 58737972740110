<template>
	<!-- <div id="app" @keydown.32.prevent="togglePlayer" ref="app" tabindex=1> -->
	<div id="app" ref="app" tabindex=1 class="app-root">
		<v-app data-app>
			<LoadingScreen :show="isLoading && isNative" />
			<mainHeader></mainHeader>

			<pwa></pwa>

			<SwipeNavigation direction="left">
				<div class="swipe-area left"></div>
			</SwipeNavigation>
			<SwipeNavigation direction="right">
				<div class="swipe-area right"></div>
			</SwipeNavigation>

			<v-main>
				<router-view/>
			</v-main>
			<mainFooter v-if="!['search'].includes($route.name)"></mainFooter>

			<KeepAlive>
				<player/>
			</KeepAlive>

			<transition name="fade" v-if="scY > 300">
				<v-tooltip bottom close-delay="0" transition="false">
					<template v-slot:activator="{ on, attrs }">
						<div id="page-top" @click="toTop" v-bind="attrs" v-on="on"></div>
					</template>
					<span>Back to top</span>
				</v-tooltip>
			</transition>

			<!-- <attentionMobileVersion v-if="attentionMobileVersionDialog" v-model="attentionMobileVersionDialog"></attentionMobileVersion> -->
			<snackNextAlbum></snackNextAlbum>
			<thisActionStopPlay v-if="actionStopPlay" v-model="actionStopPlay"></thisActionStopPlay>

      <audio id="player" preload="auto" controls="controls" style="position: absolute; width: 0; height: 0; visibility: hidden; pointer-events: none;"></audio>

		</v-app>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import mainHeader from '@/components/main/header';
import mainFooter from '@/components/main/footer';

const player = () => import("@/components/player");
// const attentionMobileVersion = () => import('@/components/dialogs/attentionMobileVersion');
const snackNextAlbum = () => import('@/components/snackbars/snackNextAlbum');
const thisActionStopPlay = () => import("@/components/dialogs/thisActionStopPlay");
const pwa = () => import('@/components/dialogs/pwa.vue');
import SwipeNavigation from '@/components/SwipeNavigation.vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
	components: {
		mainHeader,
		mainFooter,
		player,
		// attentionMobileVersion,
		snackNextAlbum,
		thisActionStopPlay,
		pwa,
		SwipeNavigation,
		LoadingScreen
	},
	data: () => ({
		name: '',
		scTimer: 0,
		scY: 0,
		// attentionMobileVersionDialog: false,
		actionStopPlay: false,
		changeTab: false,
		isLoading: true,
		isNative: Capacitor.isNativePlatform()
	}),
	watch: {
		currentPage() {
			if (this.currentPage !== 'albumsList') {
				this.$store.commit('SET_SHUFFLE', false);
			}
		},
	},
	computed: {
		...mapGetters({
			playerStatus: 'PLAYER_STATUS',
			playing: 'PLAYING',
			albums: 'PLAY_LIST_ALBUMS',
			currentTrack: 'CURRENT_TRACK',
			currentAlbum: 'CURRENT_ALBUM',
			currentTrackIdx: 'CURRENT_TRACK_IDX',
			currentPage: 'CURRENT_PAGE',
		}),
		playListFirstTrack() {
			return this.playList[0];
		},
		firstTrackAlbum() {
			return this.albums.find(album => (album.playList.find((track) => (track.id === this.playListFirstTrack.id))));
		}
	},
	async created() {
		// Initialize search configuration
		this.$store.dispatch('INITIALIZE_SEARCH');
		// Show loading screen immediately
		this.isLoading = true;
		await this.$nextTick();

		// Start loading data in parallel
		const dataPromise = this.getDataFromStore();

		// Add a short delay for stability
		await new Promise(resolve => setTimeout(resolve, 200));

		// Hide Capacitor splash with smooth fade
		if (Capacitor.isNativePlatform()) {
			await SplashScreen.hide({
				fadeOutDuration: 100
			});
		}

		// Wait for critical data to load
		await dataPromise;

		// Keep Vue loading screen visible for remaining time
		setTimeout(() => {
			this.isLoading = false;
		}, 2300);

		window.addEventListener('storage', function (event) {
			if (event.key === 'accessToken') {
				let accessToken = event.newValue;
				if (!accessToken) {
					this.$store.dispatch('LOGOUT');
				}
			}
		});
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		document.addEventListener('visibilitychange', this.handleVisibilityChange);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('beforeunload', this.handleVisibilityChange);
	},
	methods: {
		...mapActions({
			getDataFromStore: 'GET_DATA_FROM_STORE',
			play: 'SET_PLAY',
			pause: 'SET_PAUSE',
		}),
		isMobile() {
			return screen.width <= 760;
		},
		togglePlayer() {
			if (this.playing) {
				this.pause();
			} else {
				if (this.currentTrack) {
					this.play({
						track: this.currentTrack,
						index: this.currentTrackIdx,
						album: this.currentAlbum
					});
				} else {
					if (this.playList && this.playList.length) {
						this.play({
							track: this.playListFirstTrack,
							index: 0,
							album: this.firstTrackAlbum
						})
					}
				}
			}
		},
		handleVisibilityChange() {
			if (document.hidden) {
				this.changeTab = true;
			}
		},
		handleScroll: function () {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		toTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
	},
}

import 'vuetify/dist/vuetify.min.css';

</script>

<style lang="scss">
/* Critical styles to prevent flash */
html, body, #app, .v-application {
	background-color: #031020 !important;
}

.app-root {
	background-color: #031020;
	min-height: 100vh;
}

.v-application {
	background-color: #031020 !important;
	
	.v-main {
		background-color: #031020 !important;
	}

	&--wrap {
		background-color: #031020 !important;
	}
}

/* Splash screen transition */
.fade-enter-active, .fade-leave-active {
    transition: opacity 500ms ease, transform 500ms ease !important;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: scale(1.1);
}
</style>
