<template>
	<exploreLayout>
	  <div class="explore">
		<div class="head_banner">
		  <v-container full-height class="full-height">
			<h3 v-if="!subgenre">Explore Psychedelic Trance</h3>
			<h3 v-else>{{ SUBGENRE.name }}</h3>
			<nav class="explore_nav" ref="exploreNav">
			  <ul class="nav-list">
				<!-- Loop through visible items -->
				<li
				  v-for="(item, index) in visibleItems"
				  :key="item.name"
				  :class="{
					active: item.name === 'All' ? !SUBGENRE.name : SUBGENRE.name === item.name
				  }"
				  ref="navItem"
				>
				  <a @click="selectSubGenre(item.name === 'All' ? {} : item)">
					{{ item.name }}
				  </a>
				</li>
  
				<!-- "More" button shows if there are hidden items -->
				<li
				  v-if="hiddenItems.length"
				  class="more-dropdown"
				>
				  <v-menu
					v-model="isMoreDropdownOpen"
					:close-on-content-click="true"
					offset-y
					content-class="navy_blue dropdown-menu"
					:close-on-click="true"
					:nudge-top="4"
					bottom
				  >
					<template v-slot:activator="{ on, attrs }">
					  <a
						v-bind="attrs"
						v-on="on"
						class="more-button"
					  >
						More ▾
					  </a>
					</template>
					<v-list>
					  <v-list-item
						v-for="item in hiddenItems"
						:key="item.name"
						@click="selectSubGenre(item)"
						:class="{ active: SUBGENRE.name === item.name }"
						class="dropdown-item"
					  >
						<button class="dropdown-button">
						  {{ item.name }}
						</button>
					  </v-list-item>
					</v-list>
				  </v-menu>
				</li>
  
				<!-- Active subgenre highlight (the moving bubble) -->
				<div
				  class="animation_active_li"
				  :style="{
					left: activeSubgenrePosition + 'px',
					width: activeSubgenreWidth + 'px',
				  }"
				></div>
			  </ul>
			</nav>
		  </v-container>
		</div>
  
		<div class="container full_page">
		  <template v-if="mobile">
			<albumsNavMobile v-if="AUTH_DATA.accessToken"></albumsNavMobile>
			<albumsFilterMobile
			  :actions="false"
			  :subgenreFilter="'dropdown'"
			  :keysFilter="true"
			  :filterName="'explore'"
			  ref="albumsFilterMobile"
			></albumsFilterMobile>
			<albumsList
			  :whatAlbums="'all'"
			  v-if="showAlbumsOrTracks === 'albums'"
			></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
		  </template>
		  <template v-else>
			<albumsNav></albumsNav>
			<albumsFilter
			  :actions="false"
			  :subgenreFilter="'dropdown'"
			  :keysFilter="true"
			  :filterName="'explore'"
			  ref="albumsFilter"
			></albumsFilter>
			<albumsList
			  :whatAlbums="'all'"
			  v-if="showAlbumsOrTracks === 'albums'"
			></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
			<googleAdsense></googleAdsense>
		  </template>
		</div>
	  </div>
	</exploreLayout>
  </template>
  
  <script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import exploreLayout from "@/layouts/exploreLayout.vue";
  import albumsNav from '@/components/musicReleases/albumsNav';
  import albumsFilter from '@/components/musicReleases/albumsFilter';
  import albumsList from "@/components/musicReleases/albumsList";
  const trackList = () => import('@/components/musicReleases/trackList');
  import googleAdsense from "@/components/googleAdsense";
  
  // Mobile Components
  import mainSubgenres from "@/components/mobile/mainSubgenres";
  import isMobile from "@/mixins/isMobile";
  import albumsNavMobile from '@/components/musicReleases/albumsNavMobile';
  import albumsFilterMobile from '@/components/musicReleases/albumsFilterMobile';
  
  export default {
	name: 'explore',
	props: ['subgenre'],
	components: {
	  exploreLayout,
	  albumsNav,
	  albumsNavMobile,
	  albumsFilterMobile,
	  albumsFilter,
	  albumsList,
	  trackList,
	  googleAdsense,
	  mainSubgenres,
	},
	mixins: [isMobile],
	data: () => ({
	  showAlbumsOrTracks: 'albums',
	  activeSubgenrePosition: '',
	  activeSubgenreWidth: '',
	  mobileSubgenresLoading: false,
	  ogTitle: 'Psychurch',
	  ogDescription: 'Psychurch - Psychedelic Trance Music',
	  dynamicKey: 0,
  
	  // For handling visible/hidden items in the nav
	  visibleItems: [],    // Items that fit in the nav
	  hiddenItems: [],     // Items that overflow
	  isMoreDropdownOpen: false,
	  resizeTimeout: null, // For debouncing resize events
	  animationFrame: null,
	  isResizing: false,
	}),
	metaInfo() {
	  return {
		title: 'Psychurch',
		meta: [
		  { property: 'og:title', content: this.ogTitle },
		  { property: 'og:description', content: this.ogDescription },
		  { name: 'description', content: this.ogDescription },
		  {
			property: 'og:image',
			content: 'https://dev.edmverse.com/img/facebook/open_gaph_img.png.png'
		  },
		  { property: 'og:site_name', content: 'Psychurch' },
		  { property: 'og:type', content: 'website' },
		  { name: 'robots', content: 'index' }
		]
	  }
	},
	watch: {
	  BPM() {
		this.visibleAlbumsOrTracks();
	  },
	  APPLY_KEYS() {
		this.visibleAlbumsOrTracks();
	  },
	  SUBGENRE() {
		if (this.SUBGENRES.length) {
		  this.$nextTick(() => {
			this.activeSubgenre();
		  });
		}
	  },
	  '$route' (to, from) {
		// Save state when navigating away from explore page
		if (from.name === 'explore' && to.name !== 'explore') {
		  if (this.mobile) {
			const albumsFilter = this.$refs.albumsFilterMobile;
			if (albumsFilter) {
			  albumsFilter.saveFilterState();
			}
		  } else {
			const albumsFilter = this.$refs.albumsFilter;
			if (albumsFilter) {
			  albumsFilter.saveFilterState();
			}
		  }
		}

		if (to.fullPath === '/') {
		  this.$store.dispatch('UPDATE_SUBGENRE', {});
		} else if (to.name === 'favorites') {
		  this.$store.commit('SET_UPPER_FILTER', 'favorite');
		}
	  },
	  UPPER_FILTER(newValue, oldValue) {
		if (newValue !== oldValue) {
		  this.SET_GRID_ALBUM_SCROLL_ID(null);
		}
	  },
	  SUBGENRES: {
		handler() {
		  this.$nextTick(() => {
			this.calculateVisibleAndHiddenItems();
		  });
		},
		deep: true,
	  }
	},
	computed: {
	  ...mapGetters([
		'PLAYING',
		'SEARCH_STRING',
		'AUTH_DATA',
		'STATUS_FILTER',
		'COUNT_MUSIC_RELEASE', 'MUSIC_RELEASE', 'ALBUMS_CURRENT_PAGE',
		'SUBGENRE', 'BPM', 'KEYS', 'APPLY_KEYS', 'TAGS', 'ACTIVE_SAVED_FILTER', 'UPPER_FILTER',
		'ORDER_BY',
		'VIEW_ALBUMS', 'ALBUM_EXPANDED', 'SAVED_FILTERS', 'SUBGENRES',
		'CURRENT_PAGE', 'GRID_ALBUM_SCROLL_ID'
	  ]),
	  allSubgenres() {
		// Combine 'All' with the actual subgenres
		return [{ name: 'All' }, ...this.SUBGENRES];
	  }
	},
	created() {
	  // Remove scroll behavior override since it's handled by the router
	},
	mounted() {
	  this.visibleAlbumsOrTracks();
  
	  if (this.$route.name === 'favorites') {
		this.$store.commit('SET_UPPER_FILTER', 'favorite');
	  } else if (!this.SUBGENRES.length) {
		this.getSubgenres();
	  } else {
		this.activeSubgenre();
		if (this.SUBGENRE) {
		  this.selectSubGenre(this.SUBGENRE);
		}
	  }
  
	  this.calculateVisibleAndHiddenItems();
	  window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
	  window.removeEventListener('resize', this.handleResize);
	},
	methods: {
	  ...mapActions(['GET_MUSIC_RELEASE', 'CHANGE_FILTERS', 'GET_SUBGENRES', 'UPDATE_UPPER_FILTER']),
	  ...mapMutations(['SET_GRID_ALBUM_SCROLL_ID']),
  
	  visibleAlbumsOrTracks() {
		if (this.BPM.length || this.APPLY_KEYS.length) {
		  this.showAlbumsOrTracks = 'tracks';
		} else if (!this.BPM.length && !this.APPLY_KEYS.length) {
		  this.showAlbumsOrTracks = 'albums';
		}
	  },
  
	  selectSubGenre(item) {
		if (item.name === 'favorite') {
		  this.UPDATE_UPPER_FILTER('favorite');
		  this.navigateTo('/favorites');
		  return;
		}
  
		// Update store state
		this.$store.commit('SET_SUBGENRE', item);
		
		// Update URL
		if (item.name) {
		  const subgenre = item.name.trim().replace(/ /g, '-');
		  if (this.$route.path !== `/explore/${subgenre}`) {
			this.$router.push({ 
			  path: `/explore/${subgenre}`,
			  replace: true
			}).catch(() => {});
		  }
		} else if (this.$route.path !== '/') {
		  this.$router.push({ 
			path: '/',
			replace: true
		  }).catch(() => {});
		}
  
		// Update data
		this.getSubgenres();
		this.calculateVisibleAndHiddenItems();
	  },
  
	  navigateTo(path) {
		if (this.$route.path !== path) {
		  this.$router.push({ path }).catch(() => {});
		}
	  },
  
	  getSubgenres() {
		this.mobileSubgenresLoading = true;
		const subgenresParams = {
		  genre: 2,
		  'order[musicReleasesCount]': 'DESC'
		}
		this.GET_SUBGENRES(subgenresParams)
		  .then(() => {
			this.dynamicKey += 1;
			if (this.SUBGENRES.length && this.subgenre) {
			  const subgenre = this.SUBGENRES.find(
				el => el.name.trim().replace(/ /g, '-') === this.subgenre
			  );
			  if (subgenre) {
				this.$store.commit('SET_SUBGENRE', subgenre);
			  } else {
				this.$store.commit('SET_SUBGENRE', {});
				this.navigateTo('/');
			  }
			  this.activeSubgenre();
			}
		  })
		  .finally(() => {
			this.mobileSubgenresLoading = false;
		  });
	  },
  
	  handleResize() {
		// Cancel any pending animations
		if (this.animationFrame) {
		  cancelAnimationFrame(this.animationFrame);
		}
  
		// Clear existing timeout
		clearTimeout(this.resizeTimeout);
		
		// Update items immediately to prevent layout jumps
		this.calculateVisibleAndHiddenItems();
		
		// Debounce the bubble position update
		this.resizeTimeout = setTimeout(() => {
		  if (this.SUBGENRE && this.SUBGENRE.name) {
			this.activeSubgenre(true); // Pass true to indicate it's from resize
		  }
		}, 100); // Slightly longer debounce for smoother animation
	  },
  
	  activeSubgenre(isResize = false) {
		if (!this.mobile) {
		  this.$nextTick(() => {
			const navItems = [
			  ...(Array.isArray(this.$refs.navItem) ? this.$refs.navItem : [this.$refs.navItem]),
			  this.$refs.exploreNav.querySelector('.more-dropdown')
			].filter(Boolean);
			
			let active;
			const currentSubgenre = this.SUBGENRE.name;
			
			// First check if a hidden item is selected
			if (this.hiddenItems.some(item => item.name === currentSubgenre)) {
			  active = navItems.find(el => el.classList.contains('more-dropdown'));
			  
			  if (active) {
				const rect = active.getBoundingClientRect();
				const parentRect = this.$refs.exploreNav.getBoundingClientRect();
				const newLeft = rect.left - parentRect.left;
				const newWidth = rect.width;
				
				// Ensure smooth transition to More dropdown
				this.updateBubblePosition(newLeft, newWidth, isResize);
				return;
			  }
			}
			
			// Find the visible active item
			active = navItems.find(el => el.classList.contains('active'));

			if (active) {
			  const rect = active.getBoundingClientRect();
			  const parentRect = this.$refs.exploreNav.getBoundingClientRect();
			  const newLeft = rect.left - parentRect.left;
			  const newWidth = rect.width;
			  
			  const highlightBubble = this.$refs.exploreNav.querySelector('.animation_active_li');
			  
			  if (highlightBubble) {
				if (active.classList.contains('more-dropdown')) {
				  highlightBubble.classList.add('more-active');
				} else {
				  highlightBubble.classList.remove('more-active');
				}
				
				if (this.hiddenItems.some(item => item.name === currentSubgenre)) {
				  highlightBubble.classList.add('hidden');
				} else {
				  highlightBubble.classList.remove('hidden');
				}
			  }
			  
			  this.updateBubblePosition(newLeft, newWidth, isResize);
			} else if (!isResize) {
			  // Only set default position if not from resize
			  this.activeSubgenrePosition = 6;
			  this.activeSubgenreWidth = 70;
			}
		  });
		}
	  },
  
	  updateBubblePosition(newLeft, newWidth, isResize) {
		// Cancel any existing animation frame
		if (this.animationFrame) {
		  cancelAnimationFrame(this.animationFrame);
		}

		if (this.activeSubgenrePosition === '') {
		  // Initial position
		  this.activeSubgenrePosition = newLeft;
		  this.activeSubgenreWidth = newWidth;
		} else {
		  // Use transition only if not resizing or if it's the first resize update
		  if (!isResize || !this.isResizing) {
			this.animationFrame = requestAnimationFrame(() => {
			  this.activeSubgenrePosition = newLeft;
			  this.activeSubgenreWidth = newWidth;
			});
		  } else {
			// During resize, update immediately
			this.activeSubgenrePosition = newLeft;
			this.activeSubgenreWidth = newWidth;
		  }
		}
		
		// Track resize state
		this.isResizing = isResize;
	  },
  
	  calculateVisibleAndHiddenItems() {
		this.$nextTick(() => {
		  const nav = this.$refs.exploreNav;
		  if (!nav) return;
  
		  const navWidth = nav.offsetWidth;
		  const moreButtonWidth = 80; // Ensure this matches the actual width
		  let availableWidth = navWidth - moreButtonWidth;
  
		  // Reset arrays
		  this.visibleItems = [];
		  this.hiddenItems = [];
  
		  // All subgenres to iterate (including "All")
		  const items = this.allSubgenres;
  
		  // Temporary container to measure text widths
		  const tempContainer = document.createElement('div');
		  tempContainer.style.visibility = 'hidden';
		  tempContainer.style.position = 'absolute';
		  tempContainer.style.whiteSpace = 'nowrap';
		  tempContainer.style.fontSize = '16px';
		  tempContainer.style.fontFamily = getComputedStyle(nav).fontFamily;
		  document.body.appendChild(tempContainer);
  
		  // First, calculate all item widths
		  const itemWidths = items.map(item => {
			const tempItem = document.createElement('div');
			tempItem.style.display = 'inline-block';
			tempItem.style.padding = '0 10px';
			tempItem.style.fontSize = '16px';
			tempItem.innerText = item.name || 'All';
			tempContainer.appendChild(tempItem);
			const width = tempItem.offsetWidth;
			tempContainer.removeChild(tempItem);
			return width;
		  });
  
		  document.body.removeChild(tempContainer);
  
		  // Calculate how many items can fit
		  let remainingWidth = availableWidth;
		  let visibleCount = 0;
  
		  for (let i = 0; i < itemWidths.length; i++) {
			if (remainingWidth - itemWidths[i] > 0) {
			  visibleCount++;
			  remainingWidth -= itemWidths[i];
			} else {
			  break;
			}
		  }
  
		  // Split items into visible and hidden arrays while maintaining order
		  this.visibleItems = items.slice(0, visibleCount);
		  this.hiddenItems = items.slice(visibleCount);
  
		  // If no items are hidden, show all items
		  if (this.hiddenItems.length === 0) {
			this.visibleItems = items;
		  }
		});
	  }
	},
  };
  </script>
  
  <style scoped>
  /* Force the active subgenre item to stand out: */
  .nav-list li.active a {
    color: #fff !important; /* bright white for active */
  }
  
  /* The "More" dropdown styling within the component scope */
  .more-dropdown {
    position: relative; /* Parent for absolute positioning */
    margin-left: auto; /* push "More" to the far right */
  
    .more-button {
      width: 80px; /* Fixed width to match moreButtonWidth in JS */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      user-select: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 15px;
      font-weight: 400;
      white-space: nowrap;
      border-radius: 43px;
      transition: color 0.2s ease;
  
      &:hover {
        color: rgba(255, 255, 255, 0.9);
      }
    }
  
    .dropdown-menu {
      position: absolute !important; /* Remove from document flow */
      top: 100% !important; /* Position below the "More" button */
      right: 0;
      background-color: white;
      border: 1px solid #ccc;
      list-style: none;
      padding: 5px 0;
      margin: 5px 0 0 0; /* small gap so it appears below the nav bar */
      min-width: 150px;
      z-index: 9999; /* ensure it appears above the nav */
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  
    .dropdown-item {
      padding: 0;
    }
  
    .dropdown-button {
      width: 100%;
      padding: 10px 15px;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 15px;
      color: #333;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
  </style>
