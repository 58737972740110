<template>
    <div class="explore_albums_nav">
        <div class="left_buttons" v-if="!STATUS_FILTER || STATUS_FILTER === false">
            <button type="button" class="view_list" @click="changeVisibleAlbums('list')" :class="{'active': VIEW_ALBUMS === 'list'}"></button>
            <button type="button" class="view_grid" @click="changeVisibleAlbums('grid')" :class="{'active': VIEW_ALBUMS === 'grid'}"></button>
        </div>

        <template v-if="isAdmin">
            <button type="button" @click="selectionAlbums('pending')" :class="{active: UPPER_FILTER === 'pending'}">Pending</button>
        </template>

        <template v-if="AUTH_DATA.accessToken">
            <button type="button" @click="selectionAlbums('undiscovered')" :class="{active: UPPER_FILTER === 'undiscovered'}">Discover</button>
        </template>

        <button type="button" @click="selectionAlbums('explore')" :class="{active: UPPER_FILTER === 'explore'}">Explore</button>
        <button type="button" @click="selectionAlbums('popular')" :class="{active: UPPER_FILTER === 'popular'}">Popular</button>
        <button type="button" @click="selectionAlbums('trending')" :class="{active: UPPER_FILTER === 'trending'}">Trending</button>

        <template v-if="AUTH_DATA.accessToken">
            <button type="button" @click="selectionAlbums('favorite')" :class="{active: UPPER_FILTER === 'favorite'}">Favorite</button>
        </template>

        <div class="right_buttons">
            <button type="button" v-if="STATUS_FILTER === true" class="bookmarks" @click="bookmarks">Bookmarks</button>
            <button type="button" class="show_clear_filter" :class="{'active': STATUS_FILTER === true}" @click="toggleFilter">
                {{ filterButtonText }}
            </button>
        </div>
        <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import eventBus from "@/eventBus";

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");

export default {
    name: "albumsNav",
    components: {
        ComingSoonDialog
    },
    data() {
        return {
            showComingSoonDialog: false,
            isCleared: false
        }
    },
    watch: {
        UPPER_FILTER: {
            handler(current, prev) {
                if (current === 'favorite') {
                    this.SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE(1);
                } else {
                    this.SET_ALBUMS_CURRENT_PAGE(1);
                    this.SET_TRACKS_CURRENT_PAGE(1);
                }
                this.SET_ALBUM_EXPANDED('');
            }
        },
    },
    computed: {
        ...mapGetters([
            'isAdmin', 
            'AUTH_DATA', 
            'UPPER_FILTER', 
            'STATUS_FILTER', 
            'VIEW_ALBUMS', 
            'ALBUM_EXPANDED', 
            'ALBUMS_CURRENT_PAGE',
            'SUBGENRE',
            'BPM',
            'KEYS',
            'APPLY_KEYS',
            'TAGS',
            'ACTIVE_SAVED_FILTER'
        ]),
        filterButtonText() {
            if (!this.STATUS_FILTER) return 'Filters';
            
            // Show 'Clear' only if there are active filters
            const hasActiveFilters = 
                Object.keys(this.SUBGENRE).length > 0 ||
                this.BPM.length > 0 ||
                this.KEYS.length > 0 ||
                this.APPLY_KEYS.length > 0 ||
                this.TAGS.length > 0 ||
                !!this.ACTIVE_SAVED_FILTER;
            
            return hasActiveFilters ? 'Clear' : 'Hide';
        },
        isAnyFilterApplied() {
            return (
                Object.keys(this.SUBGENRE).length > 0 ||
                this.BPM.length > 0 ||
                this.KEYS.length > 0 ||
                this.APPLY_KEYS.length > 0 ||
                this.TAGS.length > 0 ||
                !!this.ACTIVE_SAVED_FILTER ||
                this.STATUS_FILTER
            );
        }
    },
    created() {
        if (!this.UPPER_FILTER) {
            if (this.AUTH_DATA.accessToken) {
                this.UPDATE_UPPER_FILTER('undiscovered');
            } else {
                this.UPDATE_UPPER_FILTER('explore');
            }
        }
    },
    mounted() {
        if (this.UPPER_FILTER === 'pending') {
            this.SET_VIEW_ALBUMS('list');
        }
    },
    methods: {
        ...mapMutations([
            'SET_STATUS_FILTER',
            'SET_BPM',
            'SET_KEYS',
            'SET_APPLY_KEYS',
            'SET_TAGS',
            'SET_ACTIVE_SAVED_FILTER',
            'SET_TRACKS_CURRENT_PAGE',
            'SET_FAVORITE_TRACKS_CURRENT_PAGE',
            'SET_SUBGENRE',
            'SET_TRACK_SCROLL_ID',
            'SET_ALBUMS_CURRENT_PAGE',
            'SET_ALBUM_EXPANDED',
            'SET_GRID_ALBUM_SCROLL_ID',
            'SET_VIEW_ALBUMS',
            'SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE'
        ]),
        ...mapActions(['UPDATE_UPPER_FILTER', 'CLEAN_FILTERS']),
        selectionAlbums(value) {
            if (value === 'popular' || value === 'trending') {
                this.showComingSoonDialog = true;
                return;
            }
            
            this.UPDATE_UPPER_FILTER(value);
        },
        toggleFilter() {
            if (!this.STATUS_FILTER) {
                // Opening the filter bar
                this.SET_STATUS_FILTER(true);
                sessionStorage.setItem('lastScrollPosition', window.scrollY.toString());
            } else if (this.filterButtonText === 'Clear') {
                // Clear all filters but keep the bar open
                this.SET_BPM([]);
                this.SET_KEYS([]);
                this.SET_APPLY_KEYS([]);
                this.SET_TAGS([]);
                this.SET_ACTIVE_SAVED_FILTER('');
                this.SET_TRACKS_CURRENT_PAGE(1);
                this.SET_FAVORITE_TRACKS_CURRENT_PAGE(1);
                this.SET_SUBGENRE({});
                this.SET_TRACK_SCROLL_ID(null);
                this.SET_ALBUMS_CURRENT_PAGE(1);
                this.SET_ALBUM_EXPANDED(null);
                this.SET_GRID_ALBUM_SCROLL_ID(null);
                
                // Clean explore grid to force refresh
                this.$store.dispatch('CLEAN_EXPLORE_GRID_ALBUMS');
                
                // Update route if on explore page
                if (this.$route.name === 'explore') {
                    this.$router.replace('/').catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
                
                // Use nextTick to ensure state updates are complete
                this.$nextTick(() => {
                    // Emit events to notify other components
                    eventBus.emit('customEvent');
                    eventBus.emit('resetBpmSlider');
                });
            } else {
                // Close the filter bar
                this.SET_STATUS_FILTER(false);
                // Restore scroll position when closing filters
                const lastPosition = sessionStorage.getItem('lastScrollPosition');
                if (lastPosition) {
                    window.scrollTo(0, parseInt(lastPosition));
                    sessionStorage.removeItem('lastScrollPosition');
                }
            }
        },
        clearAllFilters() {
            this.SET_STATUS_FILTER(false);
            this.SET_BPM([]);
            this.SET_KEYS([]);
            this.SET_APPLY_KEYS([]);
            this.SET_TAGS([]);
            this.SET_ACTIVE_SAVED_FILTER('');
            this.SET_TRACKS_CURRENT_PAGE(1);
            this.SET_FAVORITE_TRACKS_CURRENT_PAGE(1);
            this.SET_SUBGENRE({});
            this.SET_TRACK_SCROLL_ID(null);
            this.SET_ALBUMS_CURRENT_PAGE(1);
            this.SET_ALBUM_EXPANDED('');
            this.SET_GRID_ALBUM_SCROLL_ID(null);

            // Dispatch CLEAN_FILTERS action for any additional cleanup
            this.CLEAN_FILTERS();

            // Update URL if on explore page
            if (this.$route.name === 'explore') {
                this.$router.push({ path: '/explore' }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            }

            // Emit an event for other components that might need to react to this change
                eventBus.emit('filtersCleared');
        },
        changeVisibleAlbums(value) {
            this.SET_VIEW_ALBUMS(value);
        },
        bookmarks() {
            this.showComingSoonDialog = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.explore_albums_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    border-bottom: 1px solid rgba(#5F6986, .5);
    position: relative;
    margin-bottom: 8px;

    @media screen and (max-width: 999px) {
        .right_buttons {
            .bookmarks {
                display: none !important;
            }
        }
    }

    > button {
        font-size: 16px;
        color: #8591B1;
        height: 100%;
        position: relative;
        &:hover {
            color: #7481A6;
        }

        &:not(:last-child) {
            margin-right: 42px;
        }

        &.active {
            color: #fff;

            &:after {
                content: '';
                position: absolute;
                width: 35px;
                height: 3px;
                background: #20C4F5;
                border-radius: 120px;
                bottom: 0;
                left: calc(50% - 17.5px);
            }
        }
    }

    .left_buttons {
        position: absolute;
        left: 0;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
        padding-left: 15px;
    }

    .right_buttons {
        position: absolute;
        right: 0;
        display: flex;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            padding: 0 16px;
            background-color: rgba(#282C47, .6);
            border: 1px solid #000000;
            border-radius: 12px;
            font-size: 14px;
            background-position: center 12px;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &.show_clear_filter {
                display: flex;
                align-items: center;
                width: 47px;
                background-position: center 12px;
                font-size: 0;
                transition: 0.2s;
                color: #dcdcdc;

                &:hover, &.active {
                    padding-left: 40px;
                    padding-top: 2px;
                    width: 100px;
                    font-size: 15px;
                    background-position: 15px center;
                }
            }
        }
    }
}
</style>