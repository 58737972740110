export default [
    {
        path: '/settings',
        name: 'admin-settings',
        component: () => import('@/pages/admin/settings'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        },
        props: true
    },
    {
        path: '/verifications/:status?',
        name: 'verifications',
        component: () => import('@/pages/admin/verifications'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        },
        props: true
    },
    // {
    //     path: '/suggestions',
    //     name: 'suggestions',
    //     component: () => import('@/pages/admin/suggestions'),
    //     meta: {
    //         requiresAuth: true,
    //         requiresVerify: true,
    //         roles: ['ROLE_ADMIN']
    //     },
    // },
    {
        path: '/reports',
        name: 'reports',
        component: () => import('@/pages/admin/reports'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/dev-svg-icons',
        name: 'dev-svg-icons',
        component: () => import('@/pages/admin/dev-svg-icons'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/dev-api',
        name: 'dev-api',
        component: () => import('@/pages/admin/dev-api'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/dev',
        name: 'developer-playground',
        component: () => import('@/pages/test/DeveloperPlayground.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        }
    },
    {
        path: '/dev/dialogs',
        name: 'dialog-test',
        component: () => import('@/pages/test/DialogTest.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        }
    },
    {
        path: '/dev/buttons',
        name: 'button-showcase',
        component: () => import('@/pages/test/ButtonShowcase.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
            roles: ['ROLE_ADMIN']
        }
    }
];

