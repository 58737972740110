<template>
  <v-dialog
    :value="showComingSoonDialog"
    max-width="570"
    @input="$emit('input')"
  >
    <div class="dialog-wrapper coming-soon-dialog">
      <div class="dialog-header">
        <h2>
          <span class="big-letter">C</span>OMING SOON<span class="big-letter">!</span>
        </h2>
      </div>
      <div class="dialog-content bordered">
        <i>Want it faster?</i>
      </div>
      <div class="dialog-actions">
        <button 
          type="button" 
          class="tetriatary_btn" 
          @click="showComingSoonDialog = false" 
          v-ripple
        >
          Nope
        </button>
        <button 
          type="button" 
          class="c2a_btn" 
          @click="donate" 
          v-ripple
        >
          Donate
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "comingSoonDialog",
  props: ['value'],
  computed: {
    showComingSoonDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    donate() {
      this.showComingSoonDialog = false;
      window.open('https://www.patreon.com/psychurch', '_blank')
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.coming-soon-dialog {
  @include dialog((
    has-header: true,
    has-content: true,
    has-actions: true,
    content-bordered: true
  ));

  .dialog-header {
    h2 {
      text-transform: uppercase;
      
      .big-letter {
        font-size: 1.4em;
      }
    }
  }

  .dialog-content {
    font-style: italic;
  }
}
</style>
