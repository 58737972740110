<template>
	<div>

    <button class="user" id="header-user-menu 1" v-ripple @click="showUserMenu" v-click-outside="closeMenu"
            :class="{
            click: HEADER_USER_MENU && mainMenu,
            active: (PROFILE && PROFILE.photo || PROFILE && PROFILE.photo === null)
    }">
      <template v-if="PROFILE_LOADER">
        <v-skeleton-loader
          class="mx-auto border skeleton-zoom"
          max-width="40"
          type="card-avatar"
        ></v-skeleton-loader>
      </template>

      <template v-else-if="!PROFILE_LOADER">
        <img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar">
        <div v-else class="default_user_avatar"></div>
      </template>
    </button>

    <div class="wrapper_user_menu mobile" :class="{'close-menu': closeAllMenu}" v-if="HEADER_USER_MENU === true">
        <div class="user_menu" :class="{show: mainMenu, hide: !dashboardMenu && !accountMenu}">
            <div class="menu_box user_link arrow" v-ripple @click="redirectToProfile()" v-if="!PROFILE_LOADER">
                <div class="user_ava">
                    <img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar">
                    <div v-else class="default_user_avatar"></div>
                </div>
                <div class="description_ava">
                    <span class="username" v-if="PROFILE && PROFILE.fullName">{{ PROFILE.fullName }}</span>
                    <p>See profile</p>
                </div>
            </div>

            <div class="menu_box list_links arrow" @click="goToProfile('library')">
                <router-link :to="{name: 'library'}" class="i-music">Library</router-link>
            </div>
            <div class="menu_box list_links arrow calendar">
                <button type="button" class="i-ticket" id="events-calendar-show-coming-soon">Calendar</button>
            </div>
            <div class="menu_box list_links arrow"  @click="goToProfile('following')">
                <router-link :to="{name: 'following'}" class="i-heart">Following</router-link>
            </div>
            <div class="dashboard_list">Dashboard
            <div class="dashboard">
                <div class="menu_box list_links disabled dashboard_item mobile-hide">
                    <button type="button" class="disabled i-overview" id="overview-show-coming-soon">Overview</button>
                </div>
                <div class="menu_box list_links audience dashboard_item">
                    <router-link :to="{name: 'manageFollowers'}" class="i-followers">Followers</router-link>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links dashboard_item">
                    <router-link :to="{name: 'accounts'}" class="i-accounts">Accounts</router-link>
                </div>
                <div class="menu_box list_links dashboard_item">
                    <router-link :to="{name: 'uploads'}" class="i-uploads">Uploads</router-link>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links disabled">
                    <button type="button" class="disabled i-events" >Events</button>
                </div>
                <div class="menu_box list_links disabled">
                    <button type="button" class="disabled i-earnings" >Earnings</button>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links disabled">
                    <button type="button" class="disabled i-merch" >Merch</button>
                </div>
                <div class="menu_box list_links disabled">
                    <button type="button" class="disabled i-orders" >Orders</button>
                </div>
                <div class="menu_box list_links disabled">
                    <button type="button" class="disabled i-promotions" >Promotions</button>
                </div>
            </div>
            </div>
            <div class="settings_list">Settings
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'security'}" class="i-security">Security</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item mobile-hide">
                    <router-link :to="{name: 'privacy'}" class="i-privacy">Privacy</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item mobile-hide">
                    <router-link :to="{name: 'notifications'}" class="i-notifications">Notifications</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'payments'}" class="i-payments">Payments</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'subscriptions'}" class="i-subscriptions">Subscriptions</router-link>
                </div>
            </div>

            <button type="button" class="menu_box account_logout" @click="logout">Log out</button>
        </div>
    </div>

		<comingSoonDialog v-if="comingSoonDialogVisible" v-model="comingSoonDialogVisible"></comingSoonDialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import comingSoonDialog from "@/components/dialogs/comingSoonDialog";
import eventBus from "@/eventBus";

export default {
    name: 'userMenuMobile',
    props: ['hideMenu'],
    components: {
        comingSoonDialog
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            userAvatarDefault: false,
            comingSoonDialogVisible: false,

            closeAllMenu: true,
            mainMenu: false,
            dashboardMenu: false,
            accountMenu: false,
            cacheKey: new Date().getTime(),
        };
    },
    watch: {
        'AUTH_DATA.accessToken'() {
            this.GET_PROFILE();
        },
        '$route'(to, from) {
            this.closeMenu();
            // Reset safe zone opacity on route change
            document.documentElement.style.setProperty('--safe-zone-opacity', '0');
        },
        CHANGE_USER_AVA() {
            this.userAvatarDefault = false;
            this.GET_PROFILE().then(() => {
                this.$store.commit('SET_CHANGE_USER_AVA', false);
                this.cacheKey += 1;
            })
        },
        userMenu() {
            if (!this.userMenu || this.userMenu) {
                this.dashboardMenu = false;
                this.accountMenu = false;
            }
        },
        HEADER_USER_MENU() {
            if (this.HEADER_USER_MENU === true) {
                this.$store.commit('SET_HEADER_SHOPPING_CART', false);
            }
        },
        mainMenu() {
            if (this.mainMenu) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
    computed: {
        ...mapGetters(['userID', 'AUTH_DATA', 'PROFILE', 'PROFILE_LOADER', 'YOUR_ARTISTS', 'CHANGE_USER_AVA', 'HEADER_USER_MENU', 'PROFILE_LOGO']),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions(['GET_PROFILE', 'GET_YOUR_ARTISTS']),

        redirectToProfile() {
            if (this.$router.history.current.name !== 'private-profile') {
                this.$router.push({name: 'private-profile'});
            }
        },

        goToProfile(routeName) {
            this.$router.push({name: routeName});
        },

        showUserMenu() {
            this.$store.commit('SET_HEADER_USER_MENU', true);
            this.mainMenu = !this.mainMenu;

            // Set safe zone opacity when menu opens
            document.documentElement.style.setProperty('--safe-zone-opacity', this.mainMenu ? '1' : '0');

            this.closeAllMenu = false;
            if (this.accountMenu || this.dashboardMenu) {
                this.accountMenu = false;
                this.dashboardMenu = false;
            }

            // Emit event to update isShowMobileMenu in the parent component
            eventBus.emit('showMobileMenu', this.mainMenu);
        },

        closeMenu(event) {
            if (event && event.target) {
                const hasClass = event.target.classList.contains('disabled');
                if (hasClass) {
                    event.preventDefault();
                    return;
                }

                if (event.target.id === 'events-calendar-show-coming-soon' || event.target.id === 'overview-show-coming-soon') {
                    this.comingSoonDialogVisible = true;
                }
                if (event.target.id !== 'btn-open-dashboard' && event.target.id !== 'btn-open-main-menu' && event.target.id !== 'btn-open-account-settings') {
                    this.closeAllMenu = true;
                    this.$store.commit('SET_HEADER_USER_MENU', false);
                    // Reset safe zone opacity when menu closes
                    document.documentElement.style.setProperty('--safe-zone-opacity', '0');
                }
                if (event.target.id !== 'btn-open-dashboard') {
                    this.dashboardMenu = false;
                }
                if (event.target.id !== 'btn-open-main-menu') {
                    this.mainMenu = false;
                }
                if (event.target.id !== 'btn-open-account-settings') {
                    this.accountMenu = false;
                }
            }

            // Emit event to update isShowMobileMenu in the parent component
            eventBus.emit('showMobileMenu', this.mainMenu);
        },

        logout() {
            this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', '');

            // Reset the mobile menu state before logging out
            this.mainMenu = false;
            eventBus.emit('showMobileMenu', false); // Ensure the mobile menu is closed

            this.$store.dispatch('LOGOUT').then(() => {
                this.$router.push({name: 'logged-out'});
            });
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/components/_userMenuMobile.scss';
</style>
