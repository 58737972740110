<template>
	<div class="identity-verification">
		<v-container>
			<div class="page_title">
				<span>Identity Verification</span>
			</div>
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				v-on:submit.prevent>

				<div class="wrap_steps">
					<div :class="stepsCompleted[1] ? 'step one completed' : 'step one'">
						<div class="step_title">
							<span class="step-number">1</span>
							<div class="step_content">
								<div class="step_header">ID Document</div>
								<div class="step_name">Upload a photo of your Drivers license or Passport.</div>
							</div>
						</div>
						<div class="step_body">
							<upload-image @selected_photo="photoDocSelected">
								10 MB limit | JPG, PNG
							</upload-image>
						</div>
					</div>
					<div :class="stepsCompleted[2] ? 'step two completed' : 'step two'">
						<div class="step_title">
							<span class="step-number">2</span>
							<div class="step_content">
								<div class="step_header">Photo Verification</div>
								<div class="step_name">Upload a photo of yourself, holding your ID and the artist name of a profile which you'd like to claim.</div>
							</div>
						</div>
						<div class="step_body">
							<div class="example_photo">
								<preview-image :src="require('@/assets/img/upload-verification/sample.jpg')"></preview-image>
							</div>
							<upload-image @selected_photo="photoSelfSelected">
								10 MB limit | JPG, PNG
							</upload-image>
						</div>
					</div>
					<div :class="stepsCompleted[3] ? 'step three completed' : 'step three'">
						<div class="step_title">
							<span class="step-number">3</span>
							<div class="step_content">
								<div class="step_header">Online Presence</div>
								<div class="step_name">Link(s) to a webpage showing a connection between your real name and your artist name.</div>
							</div>
						</div>
						<div class="step_body">
							<v-textarea
								v-model="links"
								rows="5"
								:outlined="true"
                                :maxlength="500"
								class="links-text-area"
							></v-textarea>
						</div>
					</div>

					<div :class="stepsCompleted[4] ? 'step four completed' : 'step four'">
						<div class="step_title">
							<span class="step-number">4</span>
							<div class="step_content">
								<div class="step_header">Additional Information</div>
								<div class="step_name">Contact information & comments</div>
							</div>
						</div>
						<div class="step_body">
							<div class="wrap_fields">
								<label>Email</label>
								<v-text-field v-if="PROFILE && PROFILE.email" v-model="PROFILE.email" :outlined="true" readonly :class="'readonly'"></v-text-field>
							</div>
						</div>
						<div class="step_body">
							<div class="wrap_fields">
								<label>Comments (Optional)</label>
								<v-textarea
									v-model="comments"
									rows="5"
									:maxlength="2000"
									:outlined="true"
									class="comments-text-area"
								></v-textarea>
							</div>
						</div>

					</div>


				</div>

				<v-btn
					:disabled="!allStepsCompleted || !valid"
					class="btn_submit"
					x-large
					:loading="loading"
					@click="submitRequest">
					Submit
				</v-btn>
			</v-form>

			<error-dialog 
				v-model="errorDialog" 
				:error-details="errorDetails" 
			/>

		</v-container>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import uploadImage from '@/components/manage/uploadImage'
import previewImage from '@/components/manage/previewImage'
import errorDialog from '@/components/dialogs/error'

export default {
	name: 'identityVerification',
	props: ['artistId'],
	components: {
		uploadImage,
		previewImage,
		errorDialog
	},
	data() {
		return {
			valid: true,
			loading: false,
			uploadedDocPhoto: null,
			uploadedSelfPhoto: null,
			stepOneCompleted: true,
			email: '',
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			comments: '',
			links: '',
			errorDialog: false,
			errorDetails: {}
		}
	},
	computed: {
		...mapGetters(['PROFILE', 'FILE_URL', 'ERROR_ARTIST_VERIFICATION_REQUEST', 'FACEBOOK']),
		stepsCompleted() {
			let steps = {
				1: false,
				2: false,
				3: false,
				4: false,
			};

			if (this.uploadedDocPhoto) {
				steps[1] = true;
			}

			if (this.uploadedSelfPhoto) {
				steps[2] = true;
			}

			if (this.links) {
				steps[3] = true;
			}
			if (this.comments) {
				steps[4] = true;
			}

			return steps;
		},
		allStepsCompleted() {
			return this.uploadedDocPhoto && this.uploadedSelfPhoto && this.links;
		}
	},
	created() {
        this.GET_PROFILE()
            .catch(err => {console.error('security, GET_PROFILE', err)});
    },
    methods: {
		...mapActions(['GET_PROFILE', 'ADD_ARTIST_VERIFICATION_REQUEST', 'ADD_FILE', 'PATCH_ARTIST_VERIFICATION_REQUEST']),
		photoDocSelected(file) {
            this.uploadedDocPhoto = file;
        },
		photoSelfSelected(file) {
            this.uploadedSelfPhoto = file;
        },
		async submitRequest() {
			if (this.$refs.form.validate() && this.uploadedDocPhoto && this.uploadedSelfPhoto) {
				this.loading = true;
				
				// First create the verification request
				let data = {
					artist: `artists/${this.artistId}`,
					email: this.PROFILE.email,
					comments: this.comments,
					links: this.links,
				};

				try {
					// Create verification request first
					const verificationResponse = await this.ADD_ARTIST_VERIFICATION_REQUEST(data);
					const verificationId = verificationResponse.data.id;

					// Upload ID photo
					let formData = new FormData();
					formData.append('file', this.uploadedDocPhoto);
					formData.append('type', 'verification');
					formData.append('verification_request_id', verificationId);
					const idPhotoResponse = await this.ADD_FILE(formData);
					const idPhotoUrl = idPhotoResponse?.contentUrl || this.FILE_URL;
					
					// Upload self photo
					formData = new FormData();
					formData.append('file', this.uploadedSelfPhoto);
					formData.append('type', 'verification');
					formData.append('verification_request_id', verificationId);
					const selfPhotoResponse = await this.ADD_FILE(formData);
					const selfPhotoUrl = selfPhotoResponse?.contentUrl || this.FILE_URL;

					// Update verification request with file URLs
					const updateData = {
						id: verificationId,
						idCardPhoto: idPhotoUrl,
						selfPhoto: selfPhotoUrl
					};
					await this.PATCH_ARTIST_VERIFICATION_REQUEST(updateData);

					if (!this.ERROR_ARTIST_VERIFICATION_REQUEST) {
						this.$router.push({name: 'verification-request-submitted'});
					} else {
						this.errorDetails = {
							message: 'Artist verification request failed',
							description: this.ERROR_ARTIST_VERIFICATION_REQUEST
						};
						this.errorDialog = true;
					}
				} catch (err) {
					this.errorDetails = {
						message: err.message,
						description: err.response?.data?.['hydra:description'] || 'An unexpected error occurred',
						path: 'Artist Verification Request'
					};
					this.errorDialog = true;
					console.error(err);
				} finally {
					this.loading = false;
				}
			} else {
				this.valid = false;
			}
		}
	},
}
</script>

<style lang="scss">
@import '../../assets/styles/pages/identity_verification';
</style>
